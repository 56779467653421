@import 'variables';
@import "mixins";

.lists {
  position: fixed;
  max-width: 300px;
  z-index: 55555555555555;
  top: 16px;
  right: 16px;
  width: 100%;
}

.item {
  padding: 8px;
  font-size: 14px;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  background: $primary-color;
  color: white;
  width: 100%;
  margin-bottom: 8px;

  & button {
    background: transparent;
    border: none;
    padding: 0;
    outline: none;
    color: white;
  }

  svg {
    min-width: 22px;
  }

  &.success {
    background: $secondary-color;
  }

  &.error {
    background: $error;
  }

  &.info {
    background: $primary-color;
    gap: 10px;
    align-items: center;
    justify-content: space-between;

  }

  &.warning {
    background: $warning;

    gap: 10px;
    align-items: center;
    justify-content: space-between;

  }

  a {
    font-weight: 500;
    text-decoration: underline;
  }
}
