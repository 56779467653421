@import 'variables';
@import "mixins";

.root {
  display: flex;
  align-items: center;
  min-height: 40px;
  -webkit-align-items: center;
  padding: 0 16px;
  cursor: pointer;
  user-select: none;
  position: relative;
  font-size: 14px;
  border-radius: 20px;

  .mobileFilters {
    display: none;
  }

  .select {
    opacity: 0;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    transform: translateY(10px);
    -webkit-transform: translateY(10px);

    &.opened {
      opacity: 1;
      pointer-events: initial;
      transform: translateY(0);
      -webkit-transform: translateY(0);

    }

    .checkItem {
      display: flex;
      flex-direction: row;
      align-items: start;

      .checkItem__label {
        transform: translateY(8px);
      }
    }
  }

  .providersContainer {
    position: absolute;
    min-width: 373px;
    max-height: 686px;
    overflow-y: scroll;
    z-index: 1;
    top: 50px;
    left: -50%;
    //background-color: #1B1025;
    border-radius: 12px;
    padding: 20px;
    transform: translateY(10px);
    opacity: 1;
    transition:  0.3s opacity;
    -webkit-transition:  0.3s opacity;
    -moz-transition:  0.3s opacity;

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }


    &.features {
      min-width: 600px;

      .grid {
        width: 100% !important;

      }

    }

    .grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 10px;

      &.checkBoxesGrid {
        grid-template-columns: repeat(2, 1fr);
        width: 436px;


        .checkItem {
          &__label {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }

      .item {
        //background-color: #0E0C10;
        //border: 1px solid #0E0C10;
        max-width: 110px;
        width: 100%;
        height: 48px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        -webkit-align-items: center;
        cursor: pointer;
        transition: all .3s ease;
        -webkit-transition: all .3s ease;
        border-radius: 8px;
        padding: 5px;

        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
          transition: all .3s ease;
          -webkit-transition: all .3s ease;
        }

        &:hover {
          border-color: $spins-primary-color;
        }

        &.active {
          border-color: $primary-color;
          //background: red;
        }


      }
    }
  }

  &.hideBorder {
    border-color: transparent !important;
    padding: 0;
  }


  &__selected {
    text-transform: capitalize;
    color: #fff;
    margin-right: 25px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    white-space: nowrap;

    &.counter {
      width: 24px;
      height: 24px;
      background: #E02020;
      border-radius: 60px;
      display: flex;
      font-size: 12px;
      align-items: center;
      justify-content: center;
      color: white!important;
    }

  }


  &__icon {
    margin-right: 12px;
    color: $primary-color;
  }

  &__label {
    color:  #929396;
    //font-family: Nunito Sans;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-right: 16px;
    margin-bottom: 2px;
  }

  &__caret {
    opacity: .5;
    transform: rotate(0);
    -webkit-transform: rotate(0);
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    position: absolute;
    right: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__options {
    position: absolute;
    min-width: 206px;
    z-index: 1;
    right: 15px;
    top: 54px;
    padding: 16px;
    opacity: 0;
    pointer-events: none;
    border-radius: 12px;
    background: #2D3543;
    box-shadow: 0px 4px 8px 0px rgba(255, 255, 255, 0.06) inset, 0px 4px 6px 0px rgba(21, 25, 30, 0.30);

    &__item {
      padding: 8px;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.1px;
      background-color: transparent;
      cursor: pointer;
      border-radius: 8px;

      &.selected, &:hover {
        //background-color: #0E0C10;
      }
    }
  }

  &.active {
    .root__caret {
      opacity: 1;
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
    }

    .root__options {
      opacity: 1;
      pointer-events: initial;

    }
  }

  &:hover, &.active {
    border-radius: 20px;
    //background:  #2D3543;
    //box-shadow: 0px 4px 8px 0px rgba(255, 255, 255, 0.06) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.20), 0px -4px 6px 0px rgba(255, 255, 255, 0.05);
  }
}

.root.mobileFilter{
  width: 100%;
  padding: 0 15px;
  margin-top: 8px;
}

@media(max-width: 1620px) {
  .root.active{
    .root__options {
      min-width: 235px;
      left: 0;
    }
  }

  .root {
    display: none;
    display: flex;

    .desktopFilters {
      display: none;
    }

    .mobileFilters {
      &.opened {
        display: block !important;
        position: unset;
        transform: unset;
        width: 100%;
        border-radius: 0;
        top: 0;
        left: 0;

      }
    }
  }

  .root.mobileFilter {
    display: flex;
    //margin-top: 20px;
    border-radius: 20px;
    width: 100%;
    position: relative;
    padding: 0 15px;
    //border: 1px solid  #2D3543;
    //background:  #15191E;
    //box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.15) inset, 0px -2px 1px 0px rgba(255, 255, 255, 0.10) inset;

  }

  .root:hover, .root.active{
    border-color: transparent!important;
  }

  .root__options {
    &.categ_page {
      display: none;
    }
  }

}