@import 'variables';
@import "mixins";

.slider {
  margin-bottom: 10px;

  &__title {
    font-weight: 600;
    font-size: 52px;
    line-height: 52px;
    color: #FFFFFF;
    margin-top: 0;
    margin-bottom: 28px;
  }

  &__moreInfo {
    font-weight: 600;
    font-size: 14px;
    color: #15191E;
    margin-left: 165px;
    width: 380px;
    position: absolute;
    z-index: 10;
    top: 30%;

    button {
      width: fit-content;
    }

    a {
      color: #15191E;
      text-decoration: none;
    }
  }

  &__child {
    width: 100%;
    border-radius: 0 0 30px 30px;

    .slick-dots {
      display: none;
    }
  }
}

.sliderBlock {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  position: relative;

  img {
    max-width: 100%;
    height: auto;
    width: 100%;
  }

}

.mobileSlider {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  padding-left: 55px;
  display: none;
}

.imagesSliderMobile {
  .slider__moreInfo {
    bottom: 82px;
  }
}

@media (max-width: 1400px) {
  .slider {
    &__moreInfo {
      margin-left: 90px;
    }
  }
}

@media (max-width: 1200px) {
  .sliderBlock {
    padding-left: 0;
    background-position: center;
    justify-content: center;
  }

  .slider{
    &__moreInfo {
      display: flex;
      flex-direction: column;
      margin-left: 0;
      width: 250px;
      left: 30px;

    }

    &__title {
      font-size: 24px;
      margin-bottom: 10px;
      width: 350px;

    }
  }
}

@media (max-width: 1070px) {
  .slider__moreInfo {
    //margin-left: -700px;
  }
}

@media (min-width: 801px) {
  .imagesSliderMobile {
    display: none;
  }
}

@media (max-width: 800px) {
  .imagesSlider:not(.imagesSliderMobile) {
    display: none;
  }

  .imagesSliderMobile {
    position: relative;

    button {
      position: absolute;
      bottom: 20px;
    }
  }
}

@media (max-width: 850px) {
  .slider__child{
    border-radius: 0;
  }
}

@media (max-width: 550px) {
  .imagesSliderMobile {
    button {
      bottom: 10px;
      min-height: 40px;
    }
  }
}

@media (max-width: 420px) {
  .imagesSliderMobile {

    button {
      bottom: 10px;
      min-height: 34px;
    }
  }
}





























