@import 'variables';
@import "mixins";

.root {
  position: relative;
  padding-bottom: 40px;

  .themes__mySwiper {
    margin-top: 16px;
  }

  .furnizori {
    height: 35px;
    width: 500px;

    .furnizoriHover_seeMore {
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
      background: #1C1820;
      margin-left: 35px;
      padding: 8px 10px 8px 12px;
      border-radius: 20px;
      border: 1px solid #27222D;
      cursor: pointer;
      display: none;
      color: #FFFFFF;
    }

    &:hover {
      .furnizoriHover_seeMore {
        display: block;
        color: white;
        svg {
          margin-bottom: 2px;
        }
      }
    }
  }

  .furnizori_seeMore {
    display: none;
    position: absolute;
    right: 0px;
    background: transparent;
    color: #EEB665;
    border: none;
    top: 6px;
    &:hover {
      color: #EEB665;
    }
  }
  .furnizori_seeMore svg {
    color: #EEB665;
  }
}

.root__grid {
  padding-left: 50px;
}

.item {
  //background-color: $dark1;
  border: 1px solid rgba(39, 34, 45, 0);
  border-radius: 8px;
  width: 126px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  overflow: hidden;
  padding: 0 5px;
  margin: 0 8px 8px 0;
  //transition: all .3s ease;
  //-webkit-transition: all .3s ease;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;

  }

  &:hover {
    border-color: $spins-primary-color!important;
    box-shadow: 0px 1px 2px 0px rgba(255, 255, 255, 0.60) inset, 0px -1px 2px 0px rgba(0, 0, 0, 0.40) inset;

  }
}

@media (max-width: 1200px) {
  .root {
    .furnizori-seeMore {
      display: block;
    }

    .furnizori:hover {
      .furnizoriHover-seeMore {
        display: none;
      }
    }

    .furnizori-seeMore {
      display: block;
    }
  }
}

@media(max-width: 991px) {


  .root {
    padding-bottom: 20px;
  }

  .item {
    width: 92px;
    height: 56px
  }
}



@media(max-width: 575px) {
  .root {

    .furnizori {
      margin-bottom: 0!important;
    }

    .furnizori-seeMore {
      font-size: 13px;
      top: -2px;
    }
  }
}
