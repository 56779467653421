@import 'variables';
@import "mixins";

.steps {
  display: flex;
  list-style: none;
  padding: 0;
  //margin: 0 0 62px;
  margin: 0 !important;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;

  &__item {
    width: 26px;
    height: 26px;
    border: 2px solid currentColor;
    border-radius: 50%;
    position: relative;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;

    &::before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      background-color: $primary-color;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transition: all .3s ease;
      -webkit-transition: all .3s ease;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      opacity: 0;
    }

    &.active {
      color: $spins-primary-color !important;
      transition-delay: .4s;
      -webkit-transition-delay: .4s;

      &::before {
        opacity: 1;
        transition-delay: .4s;
        -webkit-transition-delay: .4s;
      }
    }
  }

  &__divider {
    flex: 1;
    height: 2px;

    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: $primary-color;
      transition: all .3s ease;
      -webkit-transition: all .3s ease;
      width: 0;
    }

    &.active {
      &::before {
        width: 100%;
      }
    }
  }
}

.tabs {
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 32px;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #27222D;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1px;
    z-index: 0;
  }

  &__item {
    outline: none;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid transparent;
    color: #8F84A4;
    height: 38px;
    line-height: 1;
    padding: 0 24px;
    position: relative;
    z-index: 1;
    font-size: 12px;
    font-weight: 600;

    &__active {
      color: $primary-color;
      border-color: $primary-color;
    }
  }
}

.modal {
  max-width: 560px;
  width: 100%;

  &__passwordRules {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    margin: 0 0 40px;
    text-align: left;
    padding: 0;
    background: transparent;
  }

  &__field {
    margin-bottom: 16px !important;
  }
}

.row {
  margin-right: -8px;
  margin-left: -8px;
}

.col {
  padding-right: 8px;
  padding-left: 8px;

}

.paragraph {

  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: left;

  a {
    color: var(--theme-primary-yellow);
    text-decoration: underline;

    &:hover {
      color: var(--theme-white-black-text) !important;
    }
  }
}

.verification {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .attempts_number {
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    text-align: start;
    padding-left: 24px;
    margin-top: 3px;
  }

  &__value {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    gap: 16px;

    &.with_label {
      flex-direction: column;
      gap: 0;

      .verification__value__field {
        width: 100%;
        margin: 0 !important;
      }


    }

    &__field {
      margin: 0 16px 0 0 !important;
      flex: 1;

    }

    &__btn {
      min-width: 160px;
    }

    .button_container {
      display: flex;
      gap: 16px;
      justify-content: center;
    }
  }

  &__divider {
    //@include contentModalWidth;
    height: 1px;
  }

  &__footer {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__codeBtn {
    min-width: 160px;
    //border-color: #27222D !important;

    &__notice {
      margin-top: 4px;
      display: block;
      //color: #8F84A4;
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
    }
  }

  &__msg {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    flex: 1;
    margin-right: 16px;
    color: #fff;
    background: #006E04;
    border-radius: 100px;
    height: 48px;
    text-align: left;
    padding: 0 12px;
    font-weight: 500;
    font-size: 13px;
    justify-content: center;

    svg {
      margin-right: 10px;
    }

    &__error {
      background: #9D0000;
    }
  }
}

.indicator {
  border: 1px solid #9D0000;
  border-radius: 8px;
  color: #9D0000;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  min-height: 48px;
  padding: 0 10px;
  display: flex;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  padding-left: 10px;
  margin-left: 0;
  width: 100%;
  margin-right: -24px;

  &__icon {

  }

  &__mobile {
    border-radius: 40px;
    min-height: 40px;
    margin-left: 0;
    margin-bottom: 16px;
    width: 100%;

    svg {
      margin-right: 8px;
    }
  }
}

.indicator:not(.indicator__mobile) {
  flex-direction: column;
  -webkit-flex-direction: column;
}

.btns {
  margin-bottom: 10px;
}

@media (min-width: 1200px) {
  .indicator__mobile {
    display: none;
  }
}

@media (min-width: 575px) {
  .verification {
    &__value {
      &.box {
        border-radius: 100px;

        .verification__value__field {
          input {
            border: none !important;
            box-shadow: unset !important;
            background: none;
          }
        }

        .verification__value__btn {
          min-height: 40px !important;
          height: 40px !important;
          margin-right: 8px;

        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .indicator:not(.indicator__mobile) {
    display: none;
  }
}

@media (max-width: 991px) {
  .modal__passwordRules {
    display: none;
  }
}

@media (max-width: 575px) {
  .verification {
    &__value {
      flex-direction: column;
      -webkit-flex-direction: column;
      align-items: stretch;
      -webkit-align-items: stretch;

      &__field {
        margin-right: 0 !important;
        margin-bottom: 8px !important;
      }

      &.box {
        align-items: center;

        .verification__value__field {
          width: 100%;
          margin-bottom: 0 !important;
        }

        .verification__value__btn {
          width: 160px;
          text-align: center;
        }
      }
    }

    &__footer {
      flex-direction: column;
      -webkit-flex-direction: column;
      align-items: stretch;
      -webkit-align-items: stretch;
    }

    &__msg {
      margin-bottom: 8px;
      margin-right: 0;
      min-height: 48px;
    }
  }

  .steps {
    margin-bottom: 20px;
  }
}

@media (max-width: 400px) {
  .verification {
    &__value {
      .button_container {
        button {
          font-size: 12px !important;
          min-width: unset;
          min-height: 40px;
        }
      }
    }
  }
}