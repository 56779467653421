@import 'variables';
@import "mixins";

.themes__image {
  max-width: 100%;
  height: auto;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
  gap: 8px;
  padding-bottom: 100px;

  &__col {
    aspect-ratio: 440.5/250.44;
    flex: 24%;

    .cardLink {
      min-width: 100%;
      height: 100%;
      position: relative;
    }

    @include gameThemeBox;

    &:hover {
      .item__button {
        opacity: 1;
      }
    }

    img {
      z-index: 898989999;
      max-width: 100%;
      height: auto;
      transition: all .3s ease;
      -webkit-transition: all .3s ease;
    }
  }
}

.item {
  position: relative;
  margin-bottom: 8px;
  display: block;

  img {
    filter: none;
    -webkit-filter: none;
    transform: scale(1);
    -webkit-transform: scale(1);
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
  }

  &__button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    white-space: nowrap;
    opacity: 0;
  }

  &:hover {
    img {
      transform: scale(1.05);
      -webkit-transform: scale(1.05);
      filter: brightness(0.5);
      -webkit-filter: brightness(0.5);
    }

    .item__button {
      opacity: 1;
    }
  }
}

@media (max-width: 1300px) {
  .item__button {
    font-size: 11px;
    min-height: unset;
    padding: 8px 16px;
  }
}


@media (max-width: 1199px) {
  .item__button {
    display: none;
  }
}

@media (max-width: 991px) {
  .grid__col {
    flex: 0 0 32.7%;
    width: 32.7%;
  }
}

@media (max-width: 884px) {
  .grid__col {
    flex: 0 0 32.3%;
    width: 32.3%;
  }
}


@media (max-width: 575px) {
  .grid__col {
    flex: 0 0 48.5%;
    width: 48.5%;
  }
}