@import "mixins";

.info {
  padding-bottom: 100px;
  //border: 1px solid #0B3566;
}

.border-block {
  border: 1px solid green;
}

.infoTitle {
  text-transform: uppercase;
  color:  #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.info_section {
  padding-bottom: 36px;

  p {
    font-size: 16px;
  }
}

.border-block {
  scroll-behavior: smooth;
}

.titleContainer {
  margin-bottom: 12px;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  h2 {
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    transform: translateY(1px);
    text-transform: uppercase;
    margin-bottom: 0;
    margin-right: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    //color: #fff;
  }

  button {
    display: none;
    white-space: nowrap;

    .btn_icon {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 3px;

      span {
        width: 3px;
        height: 3px;
        border-radius: 10px;
        background-color: #fff;
      }
    }
  }

  .compartment_name {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }

  .arrow_container {
    display: none;
    margin: 0 8px;
  }

  .back_to_profile {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;

    svg {
      color: #fff;
      height: 18px;
    }
  }

  .unverifiedWarning {
    padding: 12px 32px;
    border: 1px solid $spins-danger2;
    border-radius: 54px;
    line-height: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    //DON'T DELETE!!!
    font-family: "Poppins", sans-serif;
  }

  @media(max-width: 1300px) {
    button {
      display: flex;
      align-items: center;
      background: 0;
      padding: 4px 8px 4px 4px;
      border-radius: 30px;
      transition: 0.2s all;
      -webkit-transition: 0.2s all;
      border: 1px solid rgba(0, 0, 0, 0);

      &:hover {
        border: 1px solid $spins_border_gray_color;
      }
    }

    .arrow_container {
      display: block;
    }
  }



  @media(max-width: 767px) {
    flex-wrap: wrap;

    h2 {
      font-size: 20px;
      transform: none;
    }

  }

  @media(max-width: 763px) {
    .unverifiedWarning {
      margin-top: 8px;
    }
  }

  @media(max-width: 747px) {
    h2 {
      font-size: 20px;
      transform: none;
    }
  }

  @media(max-width: 640px) {
    button {
      .btn_icon {
        max-width: 30px !important;
        min-width: unset;
        height: 30px !important;
        padding: 0;
      }
    }

    .compartment_name {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }

    .back_to_profile {
      min-width: 30px;
      width: 30px;
      height: 30px;
      margin-right: 3px;

      svg {
        height: 16px;
      }

    }

    h2 {
      font-size: 16px;
      transform: none;
    }
  }

  @media(max-width: 450px) {
    flex-wrap: wrap;

  }



}

@media (max-width: 1000px) {
  .info_section {
    tr {
      th {
        p {
          font-size: 12px !important;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .infoBlock.border_block > p {
    font-size: 13px;
  }
  .infoBlock.border_block {
    padding: 20px;
  }
}

@media (max-width: 850px) {
  .info_section {
    tr {
      th {
        p {
          font-size: 11px !important;
        }
      }
    }
  }
}

@media (max-width: 790px) {
  .info_section {
    .border_block {
      overflow-x: scroll;
    }
  }
}


@media(max-width: 575px) {

  .titleContainer {
    &.with_margin {
      margin-top: 16px;
    }

    .back_to_profile {
      top: -30px;
    }
  }

  .info {
    padding-bottom: 30px;
  }

  .info_section {

    .border_block {
      padding: 16px !important;
    }

    p {
      font-size: 14px;
    }
  }
}

@media(max-width: 499px) {
  .titleContainer {
    .unverifiedWarning {
      margin-top: 12px;
    }
  }
}