@import "mixins";
@import "variables";

.centered_container {
  width: 100%;
  overflow: hidden;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 34px;

  .run_btn {
    width: fit-content;
    margin: 0 auto;
  }

  .headings {

    &.end {
      text-align: center;

      h2 {
        font-size: 24px;
        color: var(--theme-white-black-text);
        font-weight: 700;
      }

      span {
        color: var(--theme-primary-yellow);
      }
    }

  }

  h3 {
    text-align: center;
    font-weight: 700;
    color: var(--theme-white-black-text);
  }

  .img {
    width: 600px;
    max-width: 100% !important;
    max-height: 600px;
    margin: 0 auto;

    img {
      max-width: 100% !important;
    }
  }

  .end_game {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .not_logged_in {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    min-height: 500px;
    text-align: center;

    strong {
      color: var(--theme-white-black-text);
    }
  }

  .grid {
    //margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 120px auto auto auto;
    grid-column-gap: 16px;
    grid-row-gap: 16px;

    .headings {
      grid-column:1 / span 2;
      max-height: 124px;
      text-align: start;

      .h2 {
        font-size: 32px;
        font-style: normal;
        font-weight: 700 !important;
        //font-family: Poppins;
      }

      span {
        color: var(--theme-column-color);

        .white, .white svg {
          color: var(--theme-white-black-text);
        }
      }
    }

    .grid_item {
      position: relative;
      z-index: 2;
    }

    .cta_section {
      grid-column: 3 / span 2;
      grid-row: 1 / span 3;
      overflow: visible;
      display: flex;
      -webkit-align-items: flex-end;
      //align-items: end;
      z-index: 1;
      padding: 12px;
      justify-content: center;
      //border: 1px solid #F00;

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        object-fit: contain;
        transform: scale(1.2);
        transform-origin: center;
        aspect-ratio: 988/623;
      }

      button {
        position: relative;
        z-index: 2;
      }
    }

    .numbers {
      max-width: 600px;
      width: 100%;
      margin: 0 auto;
      //background: #1F1B24;
      padding: 16px;
      border-radius: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-height: 80px !important;
      //@include boxYellow();
      grid-column: 5 / span 2;
      //max-height: 124px;

      .nr {
        font-weight: 700;
        display: flex;
        flex-direction: column;
        gap: 4px;

        .label {
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          color: var(--theme-column-color);

        }

        div {
          display: flex;
          align-items: center;
          gap: 8px;
          color: var(--theme-column-color);

          .white {
            color: var(--theme-white-black-text);
          }
        }
      }

      .chanse {
        //border: 1px solid $dark1;
        padding: 8px;
        border-radius: 8px;
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: center;
        color: var(--theme-white-black-text);

        svg {
          color: var(--theme-column-color);
        }
      }
    }

    .cost {
      color: var(--theme-column-color);
      font-weight: 700;
      text-align: center;

      svg {
        color: var(--theme-column-color);
      }

      .white {
        color: var(--theme-primary-yellow);
        white-space: nowrap;

        button {
          background: none;
          border: none;
        }
      }
    }

    .prize {
      border-radius: 12px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: space-between;
      padding: 12px;

      &.simple {
        @include simpleBoxBordered;
        min-height: 220px;

        .pr_count {
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          color: var(--theme-primary-yellow);
        }

        .pr_name {
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
        }

        .img_cont {
          img {
            width: 100px;
            height: 100px;
          }
        }

      }

      //&:before {
      //  content: '';
      //  display: block;
      //  position: absolute;
      //  z-index: 0;
      //  top: 1px;
      //  left: 1px;
      //  right: 1px;
      //  bottom: 1px;
      //  //background: $dark_cards_hover;
      //  border-radius: 12px;
      //}

      .inf {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
      }



      &.special{
        grid-column: span 2;
        flex-direction: row-reverse;
        max-height: fit-content;
        padding: 0;
        height: unset;
        @include shadedBoxLighter;

        .img_cont {
          padding: 16px;

          img {
            width: 144px;
            height: 144px;
            object-fit: contain;
            object-position: center;
          }
        }

        .inf {
          flex: 1;
          padding: 16px 16px 16px 0 ;
          align-items: start;
          display: flex;
          flex-direction: column;
          gap: 8px;

          .pr_name {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
          }

          .pr_count {
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
          }
        }
      }


      img {
        position: relative;
        width: 155px;
        height: auto;
      }

      span {
        position:relative;
        color: var(--theme-white-black-text);
        font-weight: 700;
      }
    }
  }

  @media (max-width: 1330px) {
    .grid {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 120px 120px auto auto;
      grid-column-gap: 16px;
      grid-row-gap: 16px;

      .headings {
        grid-column: 1 / span 4;
        text-align: center;
      }

      .numbers {
        grid-column: 1 / span 4;
      }

      .cta_section {
        grid-column: 2 / span 2;
        grid-row: 3 / span 3;

        img {
          transform: scale(1);
        }
      }

      .prize {
        &.prize_item_0, &.prize_item_1 {
          grid-column: unset;

          .img_cont {
            padding: 16px;

            img {
              width: 100px;
              height: 100px;
              object-fit: contain;
              object-position: center;
            }
          }

          .inf {
            padding: 12px 12px 12px 0 ;

            .pr_name {
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              text-align: start;
            }

            .pr_count {
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
            }
          }

        }
      }
    }
  }

  @media (max-width: 1100px) {
    .grid {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 110px 110px 70vw auto;

      .headings {
        grid-column: 1 / span 2;
        text-align: center;
      }

      .numbers {
        grid-column: 1 / span 2;
      }

      .cta_section {
        grid-column: 1 / span 2;
        grid-row: 3 / span 2;

        img {
          transform: scale(1);
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      .prize {

        &.prize_item_0, &.prize_item_1 {
          grid-column: unset;

          .img_cont {
            padding: 16px;

            img {
              width: 120px;
              height: 120px;
              object-fit: contain;
              object-position: center;
            }
          }

          .inf {
            padding: 12px 12px 12px 0 ;

            .pr_name {
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              text-align: start;
            }

            .pr_count {
              font-size: 32px;
              font-style: normal;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  @media (max-width: 750px) {
    gap: 20px;

    .grid {

      .headings {
        text-align: center;

        h2 {
          font-size: 24px;
        }

      }

      .numbers {
        padding: 10px;
        border-radius: 10px;
        height: fit-content;
        gap: 12px;

        .nr {
          div {
            font-size: 14px;
          }
        }

        .chanse {
          font-size: 14px;
        }
      }

      .cta_section {

        img {
          transform: scale(1.2);
        }
      }

      .prize {

        &.prize_item_0, &.prize_item_1 {
          grid-column: span 2;

          .inf {
            .pr_name {
              font-size: 18px;
            }

            .pr_count {
              font-size: 24px;
            }
          }

          .img_cont {
            padding: 12px;

            img {
              width: 130px;
              height: 130px;
            }
          }
        }

        .pr_count, .pr_name {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
        }
      }
    }

    .not_logged_in {
      strong {
        font-size: 12px;
      }
    }

  }
  @media (max-width: 500px) {
    .grid {
      .cta_section {

        img {
          transform: scale(1.3);
        }
      }
    }
  }

  @media (max-width: 400px) {
    .grid {
      .cta_section {

        img {
          transform: scale(1.4);
        }
      }
    }
  }

}

.footer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 100px;

  .divider {
    border-top: 1px solid var(--theme-column-color);
  }

  .content {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: var(--theme-column-color);

    a {
      color: var(--theme-primary-yellow) !important;
      cursor: pointer;
      text-decoration: none;
    }
  }
}

.modal {
  //display: none;

  .inner {
    //padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 14px;
    color: var(--theme-white-black-text);

    strong {
      font-size: 24px;
      color: var(--theme-primary-yellow);
    }

    img {
      max-width: 100%;
      border-radius: 12px;
    }

    span {
      font-size: 14px;
      //color: $dark_text2;
      font-weight: 700;
      text-align: start;


      &.yellow {
        color: var(--theme-primary-yellow);
      }
    }

    .is_prize {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 14px;

      img {
        width: 200px;
        margin: 0 auto;
      }



      .run_btn {
        width: fit-content;
        //margin-bottom: 24px !important;
        //color: #fff !important;
      }
    }

    .no_prize {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 14px;
    }
  }
}