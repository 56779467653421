@import 'variables';
@import "mixins";

.footer {
  //border-top: 1px solid #35303E;
  //margin: 0 315px 135px 315px;
  margin: 0 auto;
  width: 100%;
  border-top: 1px solid var(--Stroke, #2D3543);
  //background: var(--Dark-120, #212632);

  &.mobileSportFooter {
    margin-bottom: 40px;
    position: unset;
    display: none;
  }

  &.sportFooter {
    position: fixed ;
    z-index: 10 ;
    bottom: 0 ;
    background: #212632;
    transform: translateY(100%);
    //border-top: 1px solid $primary-color;
    max-width: 100%;
    margin-bottom: 0 !important;

    &.open {
      transform: translateY(0);
    }

  }

  .toogleFooter {
    background: var(--theme-primary-yellow);
    color: #000;
    font-size: 14px !important;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    padding: 8px 16px;
    position: absolute;
    top: -34px;
    left: 65%;
    border: none;
  }
}

.menu_logo {
  width: 170px;

  a {
    display: block;
    width: 100%;
    position: relative;
    height: 100px;

    img {
      width: 100%;
      height: auto;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.menu_heading {
  //color: #FFF;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 75px;
  transform: translateY(20px);
}

.menuContainer {
  display: flex;
  gap: 75px;
}

.menu {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  gap: 16px;
  font-weight: 500;

  &.mobile_menu_container {
    flex-direction: row;
    flex-wrap: wrap;
    gap: unset;
    column-gap: 32px;

    .menu__item {
      font-size: 18px;

      &:active {
        color: $spins-primary-color !important;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    //color: $spins-dark_text;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-decoration: none;
    margin-bottom: 14px;
    white-space: nowrap;

    &:hover {
      color: $spins-primary-color;
    }
  }
}

.contents {
  position: relative;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  max-width: $maxWidth;
  @include fullWidthElementsPadding;

  .sportFooter {
    position: fixed;
    bottom: 0;
    background-color: #000;
    z-index: 10;
  }

  &__logo {
    width: 157px;
    display: block;
    height: auto;
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    .socialMedia {
      display: flex;
      flex-direction: row;

      .socialLink {
        background: transparent;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        //border: 1px solid #84789C;
        border-radius: 50px;
        width: 40px;
        height: 40px;

        &:hover {
          border: 1px solid $spins-primary-color;
          svg {
            color: $spins-primary-color;
          }
        }
      }
    }


  }

  &__top {
    display: flex;
    justify-content: space-between;
    //border-bottom: 1px solid #2D3543;
    padding-bottom: 30px;
    flex-direction: row-reverse;
  }

  &__sides {

    &.right {
      width: 65%;
    }
  }

  &__about {
    display: flex;
    align-items: flex-start;

    p {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin-right: 32px;
      flex: 1;
    }
  }

  &__img {
    display: flex;
    gap: 10px;
    position: relative;

    .onjn_url {
      display: block;
      height: 100%;
      width: 70px;
      position: absolute;
      top: 0;
      right: 50%;

      @media (max-width: 991px) {
        width: 50px;
      }
    }
  }
}

.mobile_menu_container {
  display: none;
}

@media(max-width: 1650px) {
  .footer {
    max-width: 100%;
    //margin-top: 20px;
  }
}

@media (max-width: 1350px) {
  .contents {

    &__top {
      flex-direction: column-reverse;
      gap: 30px;
    }

    &__sides {
      width: 100% !important;
    }

    &__about {
      gap: 32px;

      p {
        margin-right: 0;
      }
    }
  }

  .menu_heading {
    margin-bottom: 21px;
    transform: none;
  }

  .menuContainer {
    display: none;
  }

  .mobile_menu_container {
    display: flex;
  }
}

@media (max-width: 1200px) {

  .contents {
    flex-direction: column;
    margin-top: 0;
  }

}

@media (max-width: 1000px) {
  .footer {
    padding: 10px 0 70px 0;
  }
}

@media (max-width: 991px){
  .contents__about {
    flex-direction: column;
    gap: 0;
  }

  .contents__logo {
    width: 100px;
    display: block;
    height: auto;
  }
}

//@media (max-width: 850px){
//  .contents {
//    padding-bottom: 75px;
//  }
//}

@media (max-width: 801px) {
  .footer {
    padding: 10px 0 140px 0;
  }
}

@media (max-width: 650px){
  .menu {
    &.mobile_menu_container {
      .menu__item {
        font-size: 16px;

      }
    }
  }
}

@media (max-width: 480px){

}

@media (max-width: 400px) {

  .contents__about p{
    font-size: 12px;
    line-height: 110%;
  }

  .menu_heading {
    font-size: 14px;
    font-weight: 600;
  }

  .menu_heading {
    margin-bottom: 30px;
  }

  .menu {
    &.mobile_menu_container {
      .menu__item {
        font-size: 14px;

      }
    }
  }
}

@media (max-width: 330px) {
  .contents {
    &__logo {
      font-size: 14px;
      font-weight: 600;
    }
  }
}