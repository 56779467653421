@import 'variables';
@import "mixins";


@mixin bgAndInnerShade {
  background: #100f32 !important;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    border-radius: 20px;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.20), 0px 4px 24px 0px #25279D inset !important;
    pointer-events: none;

  }

}

@mixin scaleAnimation {
  animation: scale_anim 3s infinite linear;
  -webkit-animation: scale_anim 3s infinite linear;
}

.modal {
  width: 400px !important;
  min-height: 730px;
  overflow: hidden;
  position: relative;
  padding: 0 !important;
  border-radius: 20px;
  @include bgAndInnerShade();

  img {
    max-width: 100%;
  }

  .bottomSection {
    z-index: 2;
    margin-top: 5px;

    .depositLock {
      color: #fff;

      .buttonContainer {
        background: $spins_gray_bg5;
        //margin: 10px auto 0;
        padding: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 8px;
        margin-top: 10px;
        border-radius: 0 0 20px 20px;
        //position: absolute;
        //bottom: 0;
        //width: 100%;

        button {
          flex: 1;
        }
      }

      .addDeposit {
        display: flex;
        text-align: end;
        justify-content: center;
        align-items: center;
        gap: 12px;
      }

      .lockMessageContainer {
        margin-bottom: 35px !important;
        max-width: 180px;

        &.messagePressSpin {
          max-width: unset;
          width: 100%;
          padding: 0 15px;
        }

        &.deposits {
          margin-bottom: 0 !important;
        }

        .lockMessage {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
        }
      }

      .depositSteps {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        //width: 360px;
        height: 56px;
        border-radius: 100px;
        gap: 8px;



        .step {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border: 2px solid #fff;
          width: 36px;
          height: 36px;
          border-radius: 100px;

          &.completed {
            border: none;
            background: rgb(217,113,49);
            background: linear-gradient(0deg, rgba(217,113,49,1) 0%, rgba(227,217,171,1) 100%);
            color: $spins_gray_bg5;
            padding: 2px;

            .tick_container {
              width: 100%;
              height: 100%;
              border-radius: 100px;
              background: rgb(91,151,35);
              background: linear-gradient(0deg, rgba(91,151,35,1) 0%, rgba(184,244,157,1) 100%);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
          }

          .stepCount {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
          }

          .depositText {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            text-transform: uppercase;
          }
        }
      }

      .allDayLock {
        margin: 0 15px 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #0E0C10;
        padding: 24px;
        gap: 8px;
        border-radius: 12px;

        span {
          text-align: start !important;
          font-weight: 500 !important;
        }

        button {
          width: 100px;
        }
      }
    }
  }
}


.overlayContainer {
  background: rgba(0,0,0,0.6);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 20px;
  z-index: 3000000000;
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: initial;
  }

  .main {
    position: relative;
    border-radius: 10px;
    height: 100%;
    background: #100f32;
    overflow: hidden;

    .rays_bg {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      animation: bg_rays_animation 3s infinite linear;
      -webkit-animation: bg_rays_animation 3s infinite linear;

      @keyframes bg_rays_animation {
        0% {
          transform: translateX(-50%) translateY(0) scale(1);
        }

        50% {
          transform: translateX(-50%) translateY(-20px) scale(1.1);
        }

        100% {
          transform: translateX(-50%) translateY(0) scale(1.0);
        }
      }
    }

    .platform {
      height: 378px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }

    .front_container {
      width: 100%;
      height: 100%;
      position: relative;

      .gift_box {
        height: 600px;
        transform: translateY(-40px);

        img, &__bg_light {
          position: absolute;

        }

        &__bg_light {
          width: 275px;
          height: 210px;
          opacity: 0.3;
          background: #FFBB7D;
          filter: blur(35px);
          border-radius: 50%;
          left: 50%;
          top: 200px;
          transform: translateX(-50%);

          @include scaleAnimation;
        }

        &__gift_bottom {
          left: 50%;
          top: 280px;
          transform: translateX(-50%);
          width: 370px;
          height: auto;
          object-fit: cover;
        }

        &__back_rays {
          left: 50%;
          top: 130px;
          width: 360px;
          height: auto;
          object-fit: cover;
          transform: translateX(-55%);
        }

        &__rays_front {
          left: 50%;
          top: 230px;
          width: 360px;
          height: auto;
          object-fit: cover;
          transform: translateX(-50%);
          @include scaleAnimation;
        }

        &__gift_upper {
          left: 50%;
          top: 220px;
          transform: translateX(-50%);
          width: 310px;
          height: auto;
          object-fit: cover;
          animation: gift_upper_anim_first 0.5s ease-out forwards, gift_upper_anim_second 4s infinite linear;
          -webkit-animation: gift_upper_anim_first 0.5s ease-out forwards, gift_upper_anim_second 4s infinite linear;

          @keyframes gift_upper_anim_first {
            0% {
              top: 220px;
            }

            100% {
              top: 190px;
            }
          }

          @keyframes gift_upper_anim_second {
            0% {
              transform: translateX(-50%) translateY(0);
            }

            50% {
              transform: translateX(-50%) translateY(-10px);
            }

            100% {
              transform: translateX(-50%) translateY(0);
            }
          }
        }

        &__stars {
          left: 50%;
          top: 70px;
          transform: translateX(-50%);
          width: 360px;
          height: auto;
          object-fit: cover;

          @include scaleAnimation;
        }

        @keyframes scale_anim {
          0% {
            transform: translateX(-50%) scale(1);
          }

          50% {
            transform: translateX(-50%) scale(1.05);
          }

          100% {
            transform: translateX(-50%) scale(1.0);
          }
        }
      }

      .prize_box {
        position: absolute;
        bottom: 20px;
        width: 100%;
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: end;

        .win_congrats {
          position: relative;

          .congrat_text {
            text-align: center;
            font-family: Poppins;
            font-size: 30px;
            font-style: normal;
            font-weight: 800;
            line-height: 110%; /* 33px */
            text-transform: uppercase;
            background: linear-gradient(180deg, #FFFFF0 0%, #FEF47F 10.93%, #FEE24F 53.12%, #FEA237 104.07%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .shadow_text {
            text-align: center;
            font-family: Poppins;
            font-size: 30px;
            font-style: normal;
            font-weight: 800;
            line-height: 110%; /* 33px */
            text-transform: uppercase;
            opacity: 0.4;
            background: linear-gradient(180deg, #FEFB90 0%, #FEE24F 53.12%, #FEA237 104.07%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            position: absolute;
            min-width: 100%;
            top: 5px;
            left: 0;
          }
        }

        .win_amount {
          margin-top: 8px;
          text-align: center;

          span {
            color: #FFF;
            text-shadow: -1.619px 0px 0px rgba(0, 0, 0, 0.15), 0px 0px 1.619px rgba(0, 0, 0, 0.25);
            font-family: Poppins;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
          }

          .amount {
            font-size: 60px;
            width: 100%;
            display: block;
          }

          .currency {
            font-size: 16px;
          }
        }

        button {
          width: fit-content;
          margin: 10px auto 0;
        }
      }
    }
  }
}

@media (max-height: 920px) {
  .modal {
    min-height: 700px;
    width: 400px !important;

    .bottomSection {
      z-index: 2;


      .depositLock {
        color: #fff;

        .lockMessage {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          font-size: 14px;
        }

        .buttonContainer {

          button {
            min-height: 40px !important;

          }
        }
      }
    }

  }

  .overlayContainer {
    .main {
      .front_container {
        .prize_box {
          .win_congrats {
            .congrat_text, .shadow_text {
              font-size: 24px;
            }
          }

          .win_amount {
            .amount {
              font-size: 40px;
            }
          }
        }
      }
    }
  }
}

@media (max-height: 720px) {
  .modal {
    min-height: 650px;
    width: 400px !important;

    .bottomSection {
      z-index: 2;

      .depositLock {
        color: #fff;

        .lockMessage {
          font-size: 14px;
        }

        .buttonContainer {

          button {
            min-height: 40px !important;
            //font-size: 9px;
          }
        }

        .depositSteps {
          margin: 8px auto ;

          .completed {
            color: $primary-color;
          }

          .step {
            .stepCount {
              font-size: 14px;
            }
          }
        }
      }
    }

  }
}

@media (max-height: 690px) {
  .overlayContainer {

    .main {
      .front_container {
        .gift_box {
          transform: translateY(-110px) scale(0.8) !important;

          &__gift_upper {
            max-width: 90%;

          }
        }

        .prize_box {
          padding-bottom: 60px !important;
        }
      }
    }
  }
}

@media (max-height: 670px) {
  .modal {
    min-height: 600px;

    .bottomSection {
      z-index: 2;

      .depositLock {
        color: #fff;

        .lockMessage {
          font-size: 14px;
        }

        .depositSteps {
          width: 300px;
          height: 50px;
          border-radius: 100px;
          margin: 8px auto;

          .completed {
            color: $primary-color;
          }

          .step {

            .stepCount {

              font-size: 14px;
            }

            .depositText {

            }
          }
        }
      }
    }

  }
}

@media (max-height: 630px) {

  .modal {
    min-height: 570px;

    .bottomSection {
      margin-bottom: 20px;
      z-index: 2;

      .depositLock {
        color: #fff;

        .lockMessage {
          font-size: 14px;
        }

        .depositSteps {
          width: 300px;
          height: 45px;
          border-radius: 100px;
          margin: 8px auto;

          .completed {
            color: $primary-color;
          }

          .step {

            .stepCount {
              font-size: 12px;
            }

            .depositText {

            }
          }
        }
      }
    }

  }
}

@media (max-width: 600px) {
  .modal {
    .bottomSection {
      .depositLock {
        .addDeposit {
          margin-bottom: 10px;
        }
      }
    }
  }

  .overlayContainer {
    .main {
      .front_container {
        .gift_box {
          height: 600px;
          transform: translateY(-70px);
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .modal {
    //width: 320px !important;
    max-width: 97vw;

    .bottomSection {
      z-index: 2;

      .depositLock {
        color: #fff;

        .addDeposit {
          padding: 0 40px;
          justify-content: start;
        }

        .lockMessageContainer {
          margin-bottom: 35px !important;
          max-width: 50%;

          &.messagePressSpin {
            max-width: unset;
            width: 100%;
            padding: 0 15px;

            .lockMessage {
              font-size: 16px;

            }
          }

          &.deposits {
            margin-bottom: 0 !important;
          }

          .lockMessage {
            font-size: 14px;

          }
        }

        .buttonContainer {
          button {
            min-height: 40px !important;
            //font-size: 10px;
          }
        }

        .allDayLock {
          display: flex;
          flex-direction: column ;
          align-items: center;
          justify-content: center;
          background: #0E0C10;
          padding: 14px;
          gap: 8px;
          border-radius: 12px;

          button {
            margin: 0 auto;
            min-height: 40px;
          }

          span {
            text-align: center !important;
            font-weight: 500 !important;
          }
        }

        .depositSteps {
          height: 50px;
          border-radius: 100px;

          .completed {
            color: $primary-color;
          }

          .step {
            width: 30px;
            height: 30px;

            .stepCount {
              font-size: 14px;
            }

            .depositText {

            }
          }
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .overlayContainer {
    padding: 10px;

    .main {
      .front_container {
        .gift_box {
          transform: translateY(-70px) scale(0.8);

          &__gift_upper {
            max-width: 90%;

          }
        }

        .prize_box {
          padding-bottom: 30px;
        }
      }
    }
  }
}

@media (max-width: 420px) {

  .modal {
    .bottomSection {
      .depositLock {

        .buttonContainer {
          gap: 10px;

          button {
            min-height: 40px !important;
            //font-size: 10px;
          }
        }
      }
    }
  }

  .overlayContainer {
    .main {
      .front_container {
        .prize_box {
          padding-bottom: 70px;

        }
      }
    }
  }
}

@media (max-width: 380px) {
  .modal {
    .bottomSection {

      .depositLock {

        .addDeposit {
          padding: 0 10px;

        }
      }
    }
  }
}

@media (max-width: 360px) {
  .modal {

    .bottomSection {
      //margin-bottom: 20px;
      z-index: 2;

      .depositLock {
        color: #fff;

        .lockMessage {
          font-size: 14px;

        }

        .buttonContainer {
          //width: 280px;
          button {
            min-height: 40px !important;
          }
        }

        .depositSteps {



          .completed {
            color: $primary-color;
          }

          .step {
            .stepCount {
              font-size: 12px;
            }

            .depositText {

            }
          }
        }
      }
    }

  }
}