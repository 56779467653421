@import 'variables';
@import "mixins";

.modal {
  width: 450px !important;

  .inner {
    display: flex;
    flex-direction: column;
    gap: 42px;

    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      img {
        width: 100px;
      }

      strong {
        font-size: 20px;
        color: var(--theme-primary-yellow);
      }
    }

    .inf {
      font-size: 14px;
      font-weight: 600;
      color: var(--theme-column-color);

      strong {
        font-weight: 700;
        color: var(--theme-white-black-text);
      }
    }

    .controllers {
      display: flex;
      flex-direction: column;
      gap: 16px;

      button {
        width: fit-content;
        margin: 0 auto;
      }

      .cancel {
        background: none;
        border: none;
        text-decoration: underline;
        color: var(--theme-primary-yellow);
        font-weight: 700;
      }
    }
  }
}