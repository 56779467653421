@import "variables";
@import "mixins";

.main {
  display: flex;
  flex-direction: column;
  gap: 30px;


  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .heading {
      margin: 0 !important;
    }

    .mobileDisapear {
      &.hidden {
        display: none;
      }
    }
  }

  &__closeBtn {
    width: 40px;
    height: 40px;
    font-size: 14px;
    border-radius: 40px;
    border: none;
    background: none;

    img {
      transform: translateX(10px);
    }

  }

  &__iframe {
    //min-height: 900px;
    //min-width: 500px;
    width: 100%;
    height: 100%;
    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  &__content {
    height: 900px;
    width: 600px;
    padding: 0 32px;
    //border: 1px solid #fff;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    ol, ul, span, p {
      text-align: start !important;
    }

    span {
      display: block;
    }

    .textContainer {

      a {
        color: $primary-color ;
      }

      &.congrats {
        color: #fff;
      }

      &.nickname {
        color: $primary-color;
        font-size: 50px;
        font-weight: 600;
        text-transform: uppercase;
      }

      &.sorryText {
        color: $primary-color;
        font-size: 35px;
        font-weight: 600;
        text-transform: uppercase;
      }

      &.nicknameSm {
        color: $primary-color;
        font-size: 18px;
        font-weight: 600;
        text-transform: capitalize;
      }

      &.soon {
        color: #fff;
        font-size: 16px;
        margin-top: 16px;

        p {
          text-align: center !important;
        }
      }
    }
  }
}

@media (max-width: 770px) {

  .main {
    &__content {
      height: 400px;
      width: 300px;
      padding: 0;
      //border: 1px solid #fff;
      overflow: scroll;
    }

    &__iframe {
      min-height: 400px;
      min-width: 250px;
      &::-webkit-scrollbar {
        display: none !important;
      }
    }
  }

}

@media (max-width: 400px) {

  .main {
    &__content {
      height: 400px;
      width: 280px;
      padding: 0;
      //border: 1px solid #fff;
      overflow: scroll;
    }

    &__iframe {
      min-height: 400px;
      max-width: 280px;

      &::-webkit-scrollbar {
        display: none !important;
      }
    }
  }

}
