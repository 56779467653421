
@import "variables";
@import "mixins";

.root {
  width: 100%;
  max-width: 1920px;
  position: sticky;
  z-index: 1;
  top: calc(100vh);
  left: 50%;
  margin: 0 auto;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s all;
  -webkit-transition: 0.2s all;

  &__relatve_container {
    position: relative;
    width: 100%;
    height: 0;
  }

  &.active {
    pointer-events: initial;
    opacity: 1;
  }

  &__button {
    border-radius: 30px;
    border: 1px solid var(--theme-border2);
    background: var(--theme-var-bg-main);
    padding: 12px;
    height: 56px;
    width: 56px;
    transition: 0.2s all;
    -webkit-transition: 0.2s all;
    position: absolute;
    right: 12px;
    bottom: 90px;
  }

  &__text {
    display: none;
  }

  svg {
    transform: rotate(270deg);
    width: 100%;
  }
}

@media (min-width: 1100px) {
  .root {
    &__button {
      &:hover {
        background: var(--theme-active-color2);
        svg {
          color: var(--theme-primary-yellow) !important;
        }
      }
    }
  }
}

@media(max-width: 1100px) {
  .root {
    top: calc(100vh - 126px);
    margin: 0;
    &__button {
      height: 40px;
      width: 40px;
      padding: 4px;



      &:active {
        background: var(--theme-active-color2);
        svg {
          color: var(--theme-primary-yellow) !important;
        }
      }
    }
  }
}

@media (max-width: 1000px) {

  .root {
    justify-content: center;
    top: 100px;

    &__button {
      height: 32px;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      padding: 0 12px;
      width: unset;
      right: unset;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }

    &__icon {
      display: none;
    }

    &__text {
      display: inline;
    }
  }
}