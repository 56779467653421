
@import 'variables';
@import "mixins";

.root {
  min-height: 48px;
  padding: 0 32px;
  cursor: pointer;
  border: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase !important;
  display: inline-flex;
  align-items: center;
  -webkit-align-items: center;
  transition: all .3s ease;
  outline: none;
  -webkit-transition: all .3s ease;
  border-radius: 100px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  justify-content: center;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;


  &.primary {
    background: var(--Yellow-Gradient, linear-gradient(180deg, #EDC353 0%, #FBB90E 17.19%, #FBB90E 67.71%, #FF9512 100%));
    color: $spins_gray_bg2;
    box-shadow: 0px 4px 8px 0px rgba(255, 255, 255, 0.60) inset, 0px -4px 8px 0px rgba(0, 0, 0, 0.40) inset, 0px 4px 8px 0px rgba(21, 25, 30, 0.20);

    &:hover {
      box-shadow: 0px 1px 2px 0px rgba(255, 255, 255, 0.6) inset, 0px -1px 2px 0px rgba(0, 0, 0, 0.4) inset;
    }

    &.is_samsung_browser {
      background: var(--theme-primary-yellow);
      color: $spins_gray_bg2;
      //box-shadow:none;

    }
  }

  &.outlined {
    border-radius: 30px;
    background: #2D3543;
    color: white;
    flex: 1 1;
    border: none;
    //padding: 16px;
    margin-right: 10px;
    padding: 0 32px;
    box-shadow: 0px 4px 8px 0px rgba(255, 255, 255, 0.06) inset, 0px 4px 6px 0px rgba(21, 25, 30, 0.3);

    &:hover {
      color: $spins-primary-color;
    }
  }

  &.large {
    min-height: 56px;
    //font-size: 16px;
  }

  &:disabled {
    //opacity: 0.2;
    cursor: default;
  }

  &.outlinedDark {
    background: transparent;
    border: 2px solid #27222D;
    color: white;
  }

  &.success {
    background: #006E04;
    color: #fff;
    box-shadow: unset;

    &:hover {
      background: #014f1b;
    }
  }

}

@media(max-width: 417px) {
  .root {
    padding: 0 16px;
  }
}

@media(max-width: 400px) {
  .root {
    padding:  0 16px;

    &.outlined {
      padding:  0 16px;
    }
  }
}
@media(max-width: 365px) {
  .root {
    font-size: 11px;
  }
}