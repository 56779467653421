@import 'variables';
@import "mixins";

.themes {
  transition: .4s all ease;
  -webkit-transition: .4s all ease;
  overflow: hidden;
  position: relative;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  .theme__Slide {
    margin-right: 10px !important;
    background: rgba(11, 53, 102, 0.27);
    @include gameThemeBox;

    .cardLinkSkeleton {
      transform: scale(0.9);
    }
  }

  .swiper-slide {
    padding-bottom: 20px;
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    text-transform: capitalize;
    padding-left: 50px;
    color: #FFFFFF;
  }

  &__limit {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #EEB665;
    padding-left: 7px;
    position: absolute;
  }

  &__image {
    z-index: 898989999;
    max-width: 100%;
    height: auto;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;

  }

  .swiper-button-prev, .swiper-button-next {
    position: absolute;
    top: 50%;
    width:20px !important;
    height:30px!important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .themes__mySwiper, .swiper-wrapper {
    padding-bottom: 10px;
  }

  .themes__mySwiper {
    padding: 8px 0 8px 0;
    max-width: calc(100% - $mainPaddingMargin1);

    @media (max-width: 1050px) {
      max-width: calc(100% - $mainPaddingMargin2);
    }

    @media (max-width: 400px) {
      max-width: calc(100% - $mainPaddingMargin3);
    }

  }


  &__box {
    position: relative;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
    @include sliderPaddingLeft;
  }
}

@media(max-width: 1000px) {
  .themes {
    &__box {
      margin-bottom: 8px;
    }
  }
}

@media(max-width: 991px) {
  .themes__image {
    width: 100%;
  }

  .themes__title {
    padding-left: 30px;
  }

  .themes {
    .theme__Slide {
      min-width: 200px!important;

      img{
        width: 200px!important;
      }

      .cardLink {
        display: block;
        width: 200px;
      }

      .cardLinkSkeleton {
        display: block;
        width: 100%;
        height: 114.8px;

      }
    }
  }
}

@media(max-width: 850px) {
  .themes__box{
    margin-bottom: 15px;
  }
}

@media(max-width: 400px){
  .themes__box{
    margin-bottom: 10px;
  }
}