@import 'variables';

.root {
  background-color: var(--theme-var-bg-main);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;

  img {
    height: auto;
    stroke: none !important;
    transition: 0.2s all;
    -webkit-transition: 0.2s all;
    clip-path: inset(0.6px);

    &.loaded {
      opacity: 1;
    }
  }
}