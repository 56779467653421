@import 'variables';
@import "mixins";

@keyframes borderBalanceValidation {
  0% {border: 1px solid transparent;
    box-shadow: #f13737 2px 2px 25px 0;}
  50% {border: 1px solid #a80404;
    box-shadow: none}
  100%{
    border: 1px solid transparent;
    box-shadow: #f13737 2px 2px 25px 0;
  }
}

@keyframes borderBalance {
  0% {
    box-shadow: 0px 0px 5px 1px rgba(251,185,14,0.9);
  }

  50% {
    box-shadow: 0px 0px 5px 1px rgba(251,185,14,0.2);
  }

  100% {
    box-shadow: 0px 0px 5px 1px rgba(251,185,14,0.9);
  }
}

@keyframes spinsStyle {
  0% {
    -webkit-box-shadow: inset 0px 0px 10px 1px rgba(251,185,14,0.4);
    box-shadow: inset 0px 0px 10px 1px rgba(251,185,14,0.4);
  }
  50% {
    -webkit-box-shadow: inset 0px 0px 10px 1px rgba(251,185,14,0);
    box-shadow: inset 0px 0px 10px 1px rgba(251,185,14,0)
  }

  100% {
    -webkit-box-shadow: inset 0px 0px 10px 1px rgba(251,185,14,0.4);
    box-shadow: inset 0px 0px 10px 1px rgba(251,185,14,0.4);
  }
}

@keyframes blinkkkk {
  0% {
    background: rgba(251, 185, 14, 0.2);
  }
  50% {
    background: rgba(251, 185, 14, 0.03);
  }
  100% {
    background: rgba(251, 185, 14, 0.2);
  }
}

.drop_down_overlay {
  display: none;
  background-color: rgba(18, 18, 18, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.btnBalance {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: auto;
  border-radius: 20px;
  padding: 2px 2px 2px 15px;
  border-radius: 20px;
  border: none;
  gap: 16px;

}

.borderProgress{
  height: 65px;
  border-radius: 50%;
}

.decimals {
  color: $spins_text_gray_color!important;
}

.balanceCurrency {
  color: $spins_text_gray_color!important;
  font-style: normal;
  line-height: 16px;
}

.useBalance {

  //color: white!important;
  margin-right: 2px;
}

.btn_balance_add {
  //border: 1px solid transparent;
  width: 36px;
  height: 100%;
  border-radius: 50%;
  background-color: $spins-primary-color;

  span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.useBalanceWrapper {
  color: #fff;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  min-width: 100px;
  font-size: 14px;
  font-weight: 700;
  font-family: Poppins, "sans-serif";
}

.section1{
  border-radius: 8px;
  padding: 8px 12px!important;
  border: 1px solid  #2D3543;
  background: #191D26;
  margin-bottom: 4px;
}

.bonus_list {
  //max-height: 350px;
  //overflow-y: auto;
  //overflow-x: hidden;
  margin-top: 4px;

}

.dropDownBalanceWrapper {
  border: 1px solid  transparent;
  //background: #2D3543;
  border-radius: 20px;

  .deposit_btn {
    margin-top: 8px;
    width: 100%;
  }

  a {
    text-decoration: none;
  }

  .dropDownBalance {
    display: none;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    overflow-x: hidden;
    max-width: calc(100vw - 16px);


    &.active {
      display: block;
    }
  }

  .btn_balance {
    position: relative;
    background: #1B1025;
    border: 1px solid transparent;
    border-radius: 30px;
    color: #EEB665;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    padding: 0;
    margin-right: 17px;

    &:hover {
      border: 1px solid $dark_text;

    }
  }

  .btnBalance_NotValidation {
    animation-name: borderBalanceValidation;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-timing-function: linear;
  }

  .btnBalance_activeSpins {
    animation-name: borderBalance;
    animation-iteration-count: infinite;
    animation-duration: 1.5s;
    animation-timing-function: linear;
  }

  .progressWrap{
    position: relative;
    width: 64px;
    height: 64px;

    &:after{
      position: absolute;
      content: "";
      width: 65px;
      height: 64px;
      //background: transparent;
      //border: 6px solid #2D3543;
      border-radius: 50%;
      top: 0px;
      left: 0px;

    }
  }

  .dropDownBalance {
    position: absolute;
    padding: 12px;
    border-radius: 12px;
    width: 340px;
    right: 48px;
    top: 70px;
    z-index: 999999;

    &__wrappers {
      a {
        text-decoration: none;
      }
    }

    //&__cashAm {
    //  border-bottom: 1px solid #27222D;
    //}

    &__itemsSpins {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      position: relative;
      color: white;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      padding: 0 8px;
      margin-bottom: 5px;
      min-height: 40px;


      &.clickable {
        cursor: pointer;
        -webkit-transition: 0.2s all;
        transition: 0.2s all;
        &:hover {
          background: rgba(63, 55, 73, 0.20);
          -webkit-transition: 0.2s all;
          transition: 0.2s all;
        }
      }

      &.dropdown {
        flex-direction: column;

        .head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 40px;
          cursor: pointer;

          svg {
            width: 16px;
            height: 16px;
            transform: rotate(90deg);
            transition: 0.2s all;
            -webkit-transition: 0.2s all;
          }
        }
      }

    }

    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      font-weight: 400;
      font-size: 13px;
      margin: 8px 0 8px 0;
      //text-transform: ;
      color: white;
      //padding-left: 10px;
    }

    &__more {
      color: #929396;
      font-family: Poppins;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%;
    }

    &__data {
      color: $spins_yellow_2;
      text-align: right;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      margin-bottom: 0;
      margin-right: 5px;
    }

    &__dataSpan {
      margin-right: 4px;
    }

    &__dataStyle {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #EEB665;
      color: #1B1025;
      //padding: 5px;
      border-radius: 50%;
      width: 27px;
      height: 27px;
      //text-align: center;
      margin-right: 10px;
      box-shadow: #664f2c 2px 2px 25px 0;
      padding-right: 0;

    }

    &__currency {
      color:  #929396;
      text-align: right;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    &__progressBlock {
      //border-bottom: 1px solid #27222D;
      position: relative;
      height: 100%;

    }

    &__percent {
      position: absolute;
      top: 55%;
      transform: translate(-50%, -50%);
      left: 50%;
      color: $spins-primary-color;
      text-align: right;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }

    &__progress {
      display: flex;
      align-items: center;
      width: 100%;
      height: 24px;
      //background-color: #15191E;
      border-radius: 30px;
      overflow: hidden;
      margin-bottom: 10px;
      position: relative;
      font-size: 12px;




      .progressData {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        width: 100%;
        font-family: "Poppins", sans-serif;
      }
    }

    &__block {
      border: 1px solid red;
    }

    &__withdraw {
      display: flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: space-between;
      line-height: 1.2;
    }

    &__btn {
      white-space: nowrap;
      border: 1px solid $primary-color;
      background-color: transparent;
      color: #fff;
      text-transform: uppercase;
      font-size: 12px;
      border-radius: 30px;
      padding: 4px 10px;
      outline: none;
      transition: all .3s ease;
      -webkit-transition: all .3s ease;

      &:hover {
        background-color: $primary-color;
        color: #000;
      }
    }

    .bonus_container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-height: 0;
      overflow: hidden;
      width: 100%;
      transition: 0.2s all;
      -webkit-transition: 0.2s all;

      .bonus_type_bonus {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 8px 0;

        .bonuses {
          display: flex;
          gap: 12px;
          width: 100%;
          justify-content: space-between;
          align-items: end;
          color: var(--theme-column-color);

          span {
            font-size: 14px;
            font-weight: 600;

          }

          .yellow {
            color: var(--theme-primary-yellow);
          }

          .range{
            display: flex;
            flex-direction: column;
            flex: 1;
            width: 100%;
            gap: 4px;
          }

          .amn {
            font-size: 12px;
            line-height: 100%;
          }
        }

        .inf {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 12px;
          margin-top: 12px;
          font-weight: 500;
          color: var(--theme-column-color);

          svg {
            color: var(--theme-primary-yellow);
            width: 18px;
            height: 18px;
          }
        }
      }

      .bonus_type_free_bets {
        display: flex;
        flex-wrap: wrap;
        padding: 16px 0;
        gap: 8px;

        .bet {
          display: flex;
          background: var(--theme-bg2);
          border-radius: 8px;
          position: relative;
          cursor: pointer;
          color: var(--theme-white-black-text);

          .ticket_start {
            padding: 6px;
            border-right: 1px dashed #fff;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 18px;
            }

            .cuts {
              position: absolute;
              right: 0;
              width: 10px;
              min-width: 10px;
              height: 10px;
              min-height: 10px;
              border-radius: 100px;
              border: none !important;

              &.top {
                top: 0;
                transform: translate(50%, -50%);
              }

              &.bottom {
                bottom: 0;
                transform: translate(50%, 50%);
              }
            }
          }

          .value {
            padding: 12px;
            font-weight: 700;
            font-size: 13px;
          }


        }
      }

      .bonus_type_free_spins {
        padding: 16px 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 16px;

        .game {
          cursor: pointer;
          position: relative;
        }
      }

      .badge {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(50%, 50%);
        min-width: 16px;
        height: 16px;
        width: fit-content;
        border-radius: 100px;
        background: var(--theme-primary-yellow);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #1B1025;
        font-size: 12px;
        font-weight: 700;
        padding: 0 3px;

        &.g {
          top: 0;
          bottom: unset;
          transform: none;
          min-width: 20px;
          height: 20px;
        }
      }
    }

    .betStyle {
      animation-name: spinsStyle;
      animation-iteration-count: infinite;
      animation-duration: 1.5s;
      animation-timing-function: linear;
    }

    .spinsStyle {
      animation-name: spinsStyle;
      animation-iteration-count: infinite;
      animation-duration: 1.5s;
      animation-timing-function: linear;
      animation-delay: 1s;
    }

    .bonusStyle {
      animation-name: spinsStyle;
      animation-iteration-count: infinite;
      animation-duration: 1.5s;
      animation-timing-function: linear;
      animation-delay: 2s;
    }

    .questBalance {

      &.clickable {
        -webkit-transition: 0.2s all;
        transition: 0.2s all;

      }

      &__section {
        min-height: 32px;
        padding: 4px 8px;
        -webkit-transition: 0.3s all;
        transition: 0.3s all;


        &.section1 {
          padding: 8px 12px !important;
          //border-bottom: 1px solid $dark1;
          cursor: pointer;

          h4 {
            //margin-left: 8px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            /* identical to box height, or 133% */
            text-transform: capitalize;
            color: #FFFFFF;
          }

          .score {
            margin-right: 8px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: $spins_yellow_2;

            .yellow {
              color: $spins-primary-color;
            }

          }
        }

        &.section2 {
          border-top: 1px solid $dark1;
          border-bottom: 1px solid $dark1;
        }

        &.section3{
          border-bottom: 1px solid $dark1;
        }
      }

      &__headings {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;

        .infoLine {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
          color: $primary-color;
        }

        .spinsQt {
          width: 24px;
          height: 24px;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $background-color;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          background-color: $primary-color;
          box-shadow: 0px 0px 15px rgba(238, 182, 101, 0.3);
        }
      }
    }
  }

  .main_item_component {
    &.open {

      .dropDownBalance__items {
        animation: unset;
        .left {
          svg {
            transform: rotate(90deg);
          }
        }

        .head {
          svg {
            transform: rotate(-90deg);
          }
        }
      }

      .bonus_container {
        max-height: 1000px;
        overflow: unset;
      }
    }
  }
}

.bonus_text {
  font-weight: 600;
  display: block;
  margin-top: 8px;
  font-size: 13px;
  text-transform: uppercase;
  color: var(--theme-white-black-text);
}

.bonusCenter {
  position: relative;
  //padding: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;

  &.blink {
    animation-name: blinkkkk;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-timing-function: linear;

    .mainIcon {
      color: var(--theme-primary-yellow);
    }
  }

  &.active {
    background: var(--theme-active-color);

    .bonus_container {
      display: flex;
    }
  }

  .bonus_container {
    position: absolute;
    left: 50%;
    top: 60px;
    transform: translateX(-50%);
    display: none;
    padding: 15px;
    border-radius: 20px;
    width: 280px;
    box-shadow: none !important;
    flex-direction: column;
    gap: 4px;

    svg {
      //fill: none !important;
      path {
        fill: none;
      }
    }

    &__items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      position: relative;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      padding: 0 12px;
      min-height: 40px;
      text-transform: capitalize;

      &.bonusStyle {
        animation-name: spinsStyle;
        animation-iteration-count: infinite;
        animation-duration: 1.5s;
        animation-timing-function: linear;
        animation-delay: 2s;

        .right {
          background: var(--theme-primary-yellow);
          color: #191d26;
        }
      }



      &:hover {
        .right {
          background: var(--theme-primary-yellow);
          color: #191d26;
        }
      }

      &.clickable {
        cursor: pointer;
        -webkit-transition: 0.2s all;
        transition: 0.2s all;

        &:hover {
          background: rgba(63, 55, 73, 0.20);
          -webkit-transition: 0.2s all;
          transition: 0.2s all;


        }
      }

      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        //&.with_image {
        //  //padding: 8px;
        //  width: 100%;
        //  justify-content: space-between;
        //}

        img {
          width: 24px;
          height: 24px;
        }

        .icon_container {
          width: 22px;

          svg {
            width: 20px;
          }
        }
      }

      .right {
        background: var(--theme-bg2);
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        transition: 0.2s all;
        -webkit-transition: 0.2s all;

        img, svg {
          max-height: 16px;
          //transform: rotate(180deg);
        }
      }
    }

    &__title {
      font-weight: 400;
      font-size: 13px;
      margin: 8px 0 8px 0;
      color: white;
    }
  }
}

.balanceValidation {
  text-decoration: none;
  display: none !important;

  p {
    color: $colorLost !important;

  }
}

@media (max-width: 1239px) {
  .balanceValidation {
    text-decoration: none;
    display: flex !important;

    p {
      color: $colorLost !important;

    }
  }
}

@media(max-width: 1000px){

  @mixin midBalance {
    bottom: 65px;
    right: 50%;
    transform: translateX(50%);
    top: auto;
  }

  .dropDownBalanceWrapper{
    margin-right: 0;

    .dropDownBalance{
      @include midBalance;
    }
  }

  .bonusCenter {
    &.blink {
      animation-name: spinsStyle;
      animation-iteration-count: infinite;
      animation-duration: 1.5s;
      animation-timing-function: linear;
    }

    &.active {
      background: rgba(0, 0, 0, 0.1);
      animation: none;

    }

    .mainIcon {
      color: var(--theme-white-black-text);
    }
    .bonus_container {

      position: fixed;
      bottom: 80px;
      top: auto;
    }
  }
}

@media(max-width: 450px){

  .drop_down_overlay {
    display: block;
  }

  .dropDownBalanceWrapper{
    margin-right: 0;

    .dropDownBalance{
      bottom: 50vh;
      right: 50%;
      transform: translate(50%, 50%);
      top: auto;
    }
  }

  .bonusCenter {
    .bonus_container {
      top: 50vh;
      bottom: unset;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }
}

