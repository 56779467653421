@import 'variables';
@import "mixins";

@mixin btnStyle {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--theme-column-color);
  border: 1px solid transparent !important;
  transition: 0.2s all;
  -webkit-transition: 0.2s all;
  padding: 0 !important;

  &:hover, &.active {
    border: 1px solid var(--theme-primary-yellow) !important;
  }

  &.blink {
    animation-name: blinkkkk;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-timing-function: linear;
  }
}

.textOverlay {
  width: 500px;
  height: 500px;
  background: #ff0000;
}

.headerContainerWrapper {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
  //width: 100vw;
  max-width: 100vw;
  top: 0;
  border-bottom: 1px solid $spins_border_gray_color;
  background: #181e2a;

  //margin-left: 72px;

  &.hide {
    transform: translateY(-130px);
  }
}

.wrapperNavBar {
  max-width: 100vw !important;
  position: relative;
  //height: 100%;

  height: $header_height;
}

.wrapperLogoBg {
  height: 94px;
  position: absolute;
  top: -3px;
  right: 50%;
  transform: translateX(50%);
  z-index: 15;

  svg {
    height: 100%;
  }
  
  @media (max-width: 1200px) {
    transform: translateX(50%) scaleX(0.75);
  }
}

.headerContainer {
  max-width: 1920px;
  margin: auto;
  height: 100%;
  @include pageContainerSidesPadding;

  &__buttons {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 999;
    gap: 12px;

    .questButton {
      background: none;
      border: none;
    }

    @media (max-width: 1150px) {
      gap: 7px;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    -webkit-align-items: center;
    width: 100%;
    position: relative;
    align-items: center;
    height: 100%;

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;     /* Internet Explorer/Edge */
    user-select: none;

    .flex_left {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      gap: 18px;

      .resp_burger {
        padding: 4px;
        border-radius: 10px;
        border: 1px solid transparent;
        transition: 0.2s all;
        -webkit-transition: 0.2s all;

        &:hover {
          border: 1px solid var(--theme-bg2);
        }
      }
    }
  }

  &__logoBg {
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
  }

  .default-page-wrap {
    background: #161827;
  }

  &__nav {
    display: flex;
    align-items: center;
    position: relative;
    gap: 5px;
    //width: 353px;
    border-radius: 20px;
    height: 40px;
    box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.15) inset, 0px -2px 1px 0px rgba(255, 255, 255, 0.10) inset;


    .headerContainer__list {
      cursor: pointer !important;
      border-radius: 50px;

      .item {
        display: flex;
        .item_text {
          margin-top: 2px;
        }
      }

      svg {
        width: 20px;
        height: 20px;
      }

    }
  }

  .nav_mobile {
    display: none;
  }

  &__navList {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    list-style: none;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;

  }

  &__logo {
    position: absolute;
    top: 5px;
    right: 50%;
    transform: translateX(50%);
    height: calc(100% + 20px);
    z-index: 44;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1200px) {
      transform: scale(0.8) translateX(calc(50% + 20px));
      transform-origin: center;
    }


    img {
      width: 153px;
      height: auto;
    }
  }

  &__mobileLogo {
    display: none;
  }

  &__icon {
    margin-right: 4px;
  }

  &__list {
    padding: 8px 14px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    //height: 40px;
    max-height: 48px;

    &.sidebar__linkSearch {
      padding: 8px 0;
    }

    a {
      //font-family: 'Nunito Sans', sans-serif;
      font-family: 'Poppins', sans-serif;

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      //color: $spins-dark_text;
      text-decoration: none;
      white-space: nowrap;

    }

    &.quest_item {
      background: rgba(238, 182, 101, 0.1);
      height: 48px !important;
      -webkit-transition: 0.3s all;
      transition: 0.3s all;
      margin-bottom: 16px;

      a {
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-right: 10px;
        transform: translateY(-1px);

        .right {
          color: $primary-color;
        }
      }

      &:hover {
        background: rgba(238, 182, 101, 0.1);
        -webkit-transition: 0.3s all;
        transition: 0.3s all;
        box-shadow: 0px 20px 80px 0px rgba(238, 182, 101, 0.10);
      }
    }
  }
}

.link {
  //font-family: 'Nunito Sans', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  //color: $spins-dark_text;
  text-decoration: none;
  white-space: nowrap;
}

.dropDown__logOut {

  &:hover{
    border-radius: 30px;
  }
}

@keyframes blinkkkk {
  0% {
    background: rgba(251, 185, 14, 0.3);
  }
  50% {
    background: rgba(251, 185, 14, 0.2);
  }
  100% {
    background: rgba(251, 185, 14, 0.3);
  }
}

.searchBlock1 {
  @include btnStyle;

  svg {
    fill: none;
  }
}

.searchBlock{
  @include btnStyle;

  &.blink {

    svg {
      width: 24px;
      color: var(--theme-primary-yellow);
    }
  }
}

.authPlaceholder {
  min-width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchInput_header{
  border-radius: 30px;
  border: 1px solid  #2D3543;
  //background:  #15191E;
  box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.15) inset, 0px -2px 6px 0px rgba(255, 255, 255, 0.06) inset;
  height: 40px;
  outline: none;
  color: white;
  padding-left: 15px;
  width: 180px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  z-index: 33;
  position: relative;



  &:focus {
    border: 1px solid $spins-primary-color!important;
  }

  &::placeholder {
    font-size: 11px;

  }
}

.responsiveSearch {
  display: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid  #2D3543;
  background: #15191E;
  box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.15) inset, 0px -2px 1px 0px rgba(255, 255, 255, 0.15) inset;

  &:focus{
    border: 1px solid $spins-primary-color!important;
  }

  &__iconResponsive {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

}

.bottomBlockSidebar{
  padding: 8px 16px;
  width: 100%;
  background: var(--theme-bg3);
}
//232B3B
.modeBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  color: white;
  border-radius: 20px;

  &__button {
    padding: 8px;
    border: none;
    background: transparent;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    flex: 1;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    border-radius: 20px;
    font-family: Poppins, "sans-serif";
    color:$spins-dark_text!important;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;

    path {
      fill: $spins-dark_text!important;
    }
  }
}

.logoWrap {
  width: 200px;
  height: 117px;
  background-size: cover;
  background-repeat: no-repeat;
}

.columnSideBar{
  color: white;
  height: 100%;
  width: 72px;
  //left: -137px;
  position: absolute;
  top: 0;
  bottom: 0;
  //background: #212632;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 4px;
  z-index: 199;


  &__wrapper {
    position: sticky;
    top: 20px;
    z-index: 5;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    -webkit-justify-content: space-between;

    .controllers {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .column_menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        .item {
          width: 48px;
          aspect-ratio: 1;
          border-radius: 50%;
          border: 1px solid transparent;
          background: none;
          padding: 0;
          @include regularTransition;

          &:hover {
            border: 1px solid var(--theme-bg2);
          }

          svg {
            width: 24px;
            height: 24px;
          }

          &.wheel {
            border-radius: 20px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

.columnFields {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  @include iconsHovered;

  &__message {
    display: flex;
    align-items: center;
    width: 52px;
    justify-content: center;
    height: 52px;
    border-radius: 50%;
    //background: $spins-primary-color;
    margin-top: 24px;
  }

  &__title {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    //color: $spins-dark_text;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.modeButton{
  border: none;
  background: transparent;
  @include iconsHovered;
}

.header__btnLog{
  max-width: 100%;
  z-index: 99;
  min-width: 309px;

  button {
    min-height: 40px;
  }

}

.mobilePromo__wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  color: #929396;
  line-height: 110%;
  gap: 8px;

  a {
    text-decoration: none;
    color: #929396;
  }
}

.mobilePromo{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  flex: 1;
  height: 72px;
  border-radius: 20px;
  //padding: 0 4px;
  padding: 8px;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;

  svg {
    width: 24px;
    height: 24px;
  }

  .text {
    margin-top: 4px;

  }
}

.sidebar__btnNotLog{
  //width: 100%;
  margin: auto;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 12px;
  height: 100%;
  width: 360px;
  //background:  #212632;
  position: sticky;
  top: 0;
  z-index: 55555;
  transform: translateX(-110%);
  -webkit-transform: translateX(-110%);
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  height: 100vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &__seeProfile {
    display: none;
  }

  &__close {
    display: block;
    background: transparent;
    border: none;
    color: grey;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;

    path{
      fill: #D2CDDB;
    }

    &:hover {
      color: #EEB665;
    }
  }

  &__logo {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 8px;

    button {
      width: 56px;
      height: 56px;
      border-radius: 20px;
      @include regularTransition;

      &:hover {
        border: 1px solid var(--theme-primary-yellow) !important;
      }
    }
    
    img {
      cursor: pointer;
      width: 125px;
      height: auto;
      object-fit: contain;
    }
  }

  &__userBlock {
    display: flex;
    margin-bottom: 15px;
  }

  &__balanceBlock {
    display: flex;
    width: 100%;
    //justify-content: space-between;
  }

  &__balance {
    color: $spins-dark_text;
    margin-right: 8px;
  }

  &__balanceItem {
    font-size: 16px;
    font-weight: 600;
    color: white;
    font-family: Poppins, "sans-serif";

    span {
      margin-left: 6px;
    }
  }

  &__currence {
    color: $spins-dark_text;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin-left: 4px;
    margin-right: 6px;
  }

  &__userData {
    display: flex;
    position: relative;
    margin-left: 16px;
    flex-direction: column;
    justify-content: space-around;
    font-size: 16px;
    color: #d2cddb;
    line-height: 1;
    flex: 1;
    align-items: flex-start;
  }

  &__userFullName {
    text-transform: capitalize;
    //color: white;
    font-size: 18px;
    font-style: normal;
    line-height: 120%;
    font-weight: 600;
    color: white;
    font-family: Poppins, "sans-serif";
    //margin-bottom: 10px;
  }

  &__userBtn {
    //display: none;
    border: none;
    background: transparent;
  }

  &__user {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__userName {
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
    color: #fff;
  }

  &__btnLog {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    color:  #15191E;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;

    button {
      flex: 1;
    }
  }

  &__btn {
    flex-direction: column-reverse;
    text-align: center;
    display: flex;
    border-radius: 20px;
    border: 1px solid $spins_border_gray_color;
    background: $spins_gray_bg2;
    box-shadow: 0px 4px 6px 0px rgba(21, 25, 30, 0.15) inset, 0px -2px 1px 0px rgba(255, 255, 255, 0.30) inset;
    padding: 16px;

    .acc_navs {
      display: flex;
      gap: 8px;
      margin-bottom: 10px;

      button {
        flex: 1;
        background: none;
        color: var(--theme-border3);
        font-family: Poppins;
        font-size: 12px;
        font-weight: 600;
        border: 1px solid transparent !important;
        text-transform: capitalize;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4px;
        border-radius: 16px;
        @include regularTransition;

        &:hover {
          border: 1px solid var(--theme-bg1) !important;
        }
      }
    }
  }

  &__btnWrap {
    display: flex;
    justify-content: space-between;
    gap: 0 16px;

    button {
      flex: 1;
      padding: unset !important;
    }
  }

  &__btnAside {
    display: none;
  }

  &__links {
    list-style: none;
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    //font-family: Nunito Sans;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    //font-style: normal;
    //font-weight: 600;
    //line-height: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    & button {
      background: transparent;
      border: none;
      outline: none;
      color: white;
      width: 100%;
      text-align: left;
      display: inline-flex;
      padding: 10px 0;
      min-height: 38px;
      align-items: center;
      -webkit-align-items: center;
      transition: 0.2s all;
      -webkit-transition: 0.2s all;
    }

    & li {
      text-transform: capitalize;
      border-radius: 100px;
      padding: 2px 0px 2px 16px;

      &:hover {
        border-radius: 30px;

        path{
          fill: $spins-primary-color;
        }
      }

      a {
        display: inline-flex;
        width: 100%;
        padding: 10px 0;
        min-height: 52px;
        align-items: center;
        -webkit-align-items: center;
        color: var(--theme-white-black-text);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        &:hover {
          //color: white;
        }
      }

      svg{
        margin-right: 15px;
        width: 26px;
        height: 26px;

        &:hover {
          color: $spins-primary-color;
        }
      }

      img {
        margin-right: 15px;
      }
    }

    @keyframes blinkkkk {
      0% {
        background: rgba(251, 185, 14, 0.2);
      }
      50% {
        background: rgba(251, 185, 14, 0.03);
      }
      100% {
        background: rgba(251, 185, 14, 0.2);
      }
    }

    @keyframes blinkkkk2 {
      0% {
        background: rgba(251, 185, 14, 0.5);
      }
      50% {
        background: rgba(251, 185, 14, 0.35);
      }
      100% {
        background: rgba(251, 185, 14, 0.5);
      }
    }

    .quest_link {
      animation-name: blinkkkk2;
      animation-iteration-count: infinite;
      animation-duration: 1.5s;
      animation-timing-function: linear;
      width: 100%;
      padding: 2px 0px 2px 16px;
      min-height: 48px;
      display: flex;
      align-items: center;
      border-radius: 30px;
      cursor: pointer;

      svg {
        width: 24px;
        color: var(--theme-primary-yellow);
      }

      a {
        display: flex;
        gap: 16px;
        align-items: center;
        text-decoration: none;
        color: inherit;
        height: 100%;
        width: 100%;
      }
    }

    .userLogOut{
      //color:  $spins-dark_text;
      //font-family: Nunito Sans;
      font-family: 'Poppins', sans-serif;

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;

      &:hover{
        //color: white!important;
      }
    }

  }

  &__lastLink {
    border-bottom: none;
  }

  &__socialBlock {
    text-align: left;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    -webkit-align-items: flex-start;
    justify-content: flex-start;
    //padding: 8px 16px 16px;
  }

  &__facebok {
    background: transparent;
    color: red;
  }

  &__socialLink {
    background: transparent;
    margin-right: 10px;
    padding: 8px;
    border-radius: 40px;    width: 40px;
    height: 40px;
    display: inline-block;

    svg {
      width: 24px;
      height: 24px;
    }

  }

  &.active {
    transition: transform .3s ease;
    -webkit-transition: all .3s ease;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    box-shadow: 12px 0px 10px -2px rgba(0, 0, 0, 0.45);


    .overlay{
      display: block
    }
  }
}

.sidebarBlock{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__controllers {
    padding: 0;

    .guest_btns {
      display: flex;
      gap: 8px;

      button {
        flex: 1;
      }
    }

    .img_buttons {
      color: #fff;
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      gap: 10px;



      .playhub_section, .prom_section {
        border-radius: 20px;
        height: 56px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        display: flex;
        flex: 1;
        //justify-content: center;
        align-items: center;
        padding: 0 16px;padding: 0 16px;
        gap: 8px;
        outline: 1px solid transparent;
        @include regularTransition;
        cursor: pointer;

        &.blink {
          animation-name: blinkWheel;
          animation-iteration-count: infinite;
          animation-duration: 1.5s;
          animation-timing-function: linear;
        }

        @keyframes blinkWheel {
          0% {
            box-shadow: 0px 0px 5px 1px rgba(251,185,14,0.9);
          }

          50% {
            box-shadow: 0px 0px 5px 1px rgba(251,185,14,0.2);
          }

          100% {
            box-shadow: 0px 0px 5px 1px rgba(251,185,14,0.9);
          }
        }

        &:hover {
          outline: 1px solid var(--theme-bg2);
        }

        span {
          font-family: Poppins;
          font-size: 14px;
        }
      }

      .playhub_section {
        background-image: url("../../../public/spins/images/play_arena.webp");

      }

      .prom_section {
        background-image: url("../../../public/spins/images/prom_bg.webp");

      }

    }


    .primary_links {
      display: flex;
      gap: 8px;
      width: 100%;
      margin-top: 18px;

      button {
        color: var(--theme-primary-yellow);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 600;
        @include regularTransition;
        border: 1px solid transparent !important;
        border-radius: 16px;
        background: none;
        flex: 1;
        padding: 5px 0;

        &.inPage, &:hover {
          border: 1px solid var(--theme-bg2) !important;

        }

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.overlay {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
}

.overlay.active {
  opacity: 1;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  pointer-events: initial;
  //left: 72px;
}

.headerContainer__logoOut {
  position: absolute;
  top: 0;
  right: 50%;
  transform: translateX(50%);
}

.overlayAccount {
  display: none;
  position: absolute;
  width: 365px;
  height: 100%;
  top: 0;
  left: -137px;
  right: 0;
  bottom: 0;
  //background-color: rgba(0,0,0,0.5);
  z-index: 9999999999;
  opacity: 0;
  pointer-events: none;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
}

.overlayAccount.active {
  display: block;
  opacity: 1;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  pointer-events: initial;
}

.accountSideBarOverlay, .infoSideBarOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: #000;
  opacity: 0;
  z-index: 97;
  pointer-events: none;
}

.termsSidebar{
  border-right: 1px solid #2D3543;
}

.accountSideBar {

  &__top_btn_container {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0 16px;
    margin-top: 8px;

    .titleButton {
      display: none;
      margin: 0;
    }
  }
}

.accountSideBar, .termsSidebar {
  position: absolute;
  //position: sticky;
  top: $header_height;
  left: 0;
  height: calc(100% - $header_height);
  width: 365px;
  max-width: 360px;
  z-index: 98;
  //overflow-y: scroll;
  transform: translateX(-100%);
  transition: left .3s,  right .3s, transform .3s ease;
  -webkit-transition: left .3s,  right .3s, transform .3s ease;
  scrollbar-width: none;

  .linksBox {
    position: sticky;
    top: $header_height;
    overflow-x: scroll;
    scrollbar-width: none;
    max-height: calc(100vh - $header_height);
    padding: 40px 8px;

    &.acc {
      padding: 0;
    }

    &::-webkit-scrollbar {
      display: none !important;
      scrollbar-width: none;
    }
  }

  &::-webkit-scrollbar {
    display: none !important;
    scrollbar-width: none;
  }

  &__close {
    background: transparent;
    border: none;
    padding: 16px 16px 16px 0;
    display: flex;
    align-items: center;
    color:  #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  &__icon {
    margin-right: 25px;
  }

  &.active {
    transition: left .3s, right .3s, transform .3s ease;
    -webkit-transition: left .3s, right .3s, transform .3s ease;
    transform: translateX(0);

  }

  .overlayAccount{
    display: block;
  }
}

.bgLogoMiddleDarkMode{

}

.bgLogoMobileDarkMode {
  display: none;
  position: absolute;
  top: -1px;
  left: -54px;

  transform: translateX(50%);
  z-index: 0;
}

.logoMobileDarkMode{
  position: absolute;
  top: -26px;
  left: -50px;
  max-height: 78px;
  transform: translateX(50%);
  z-index: 0;
  display: none;
}

.score{
  min-width: 100%;
  padding: 2px 6px ;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  background: rgba(255, 185, 80, 0.20);
  color: var(--Text-Primary, #FFF);
  font-size: 9px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  height: 12px;
  overflow: hidden;
}

.adventureCandyCrash{
  //display: none;
  //margin-left: auto;


  &__wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 1px solid  #2D3543;
    //background: #15191E;
    padding: 6px 35px;
    color: white;
    font-family: Poppins;
    margin-right: 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.15) inset, 0px -2px 1px 0px rgba(255, 255, 255, 0.10) inset;
  }
}

.mobilePromo__wrapper {
  display: none;

}

.middleLogoWrapper{
  display: none;
  position: absolute;
  top: 1px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 55;

  &__bgLogo{
    svg{
      position: absolute;
      top: 3px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 50;
    }
  }

  &__logo {
    svg{
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-47%);
      z-index: 55;
    }

  }
}

.wheel_enabled {
  color: var(--theme-primary-yellow) !important;
}

.logout_btn {
  padding: 0;
  min-height: 57px;
  width: 100%;
  border-top: 1px solid var(--theme-border4);
  display: flex;

  button {
    min-height: 57px;
  }
}

.mobileButtonSocial {
  display: flex;
  //justify-content: center;
  //margin: 12px auto 0;
  padding: 8px 16px;
  border-top: 1px solid var(--theme-border4);
  width: 100%;
}

@media (max-width: 1475px){

  .header__btnLog{
    margin-right: 0;
    min-width: 250px;
  }
}

@media (max-width: 1600px) {
  .searchInput_header {
    display: none;
  }
  //.searchBlock__icon {
  //  display: none;
  //}
  .responsiveSearch{
    display: block;
  }
}

@media (max-width: 1300px) {

  .logInGifLogo {
    display: none;
  }

  .headerContainer__inner {
    position: relative;
  }

  .gifLogoMenu {
    width: 125px;
    height: 65px;
  }

  .headerContainer__mobileLogo {
    display: block;
    position: absolute;
    top: 50%;
    /* right: 50%; */
    left: 50px;
    transform: translatey(-50%)
  }

  .sidebar {
    padding-top: 0;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &__userBlock {
      position: relative;
      //margin-top: 8px;
    }

    &__btnAside {
      display: flex;
      justify-content: space-between;
      padding: 15px 0 16px 0 ;
      text-align: center;
      margin: 0 16px;
      padding-top: 0;

      & button {
        flex: 1;
      }
    }

    &__userFullName {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: white;
      //margin-bottom: 10px;
    }

    &__userName {
      font-weight: 600;
      font-size: 16px;
    }

    &__balanceBlock {
      justify-content: flex-start;
      align-items: center;
    }

    &__balance {
      margin-right: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    &__balanceItem {
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
    }
    &__currence {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

    }


    &__btnLog {
      background: inherit;
    }

    &__userBtn {
      display: inline-block;
      background: transparent;
      border: none;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .headerContainer__buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    .header__btnLog {
      button {
        padding: 0 24px;
      }
    }
  }

  .accountSideBarOverlay {
    &.active {
      opacity: 0.3;
      pointer-events: initial;
    }
  }

  .infoSideBarOverlay {
    &.active {
      opacity: 0.3;
      pointer-events: initial;
    }
  }

  .accountSideBar, .termsSidebar {
    left: -360px;

    &.active {
      left: 0;
    }
  }

  .accountSideBar {
    &__top_btn_container {
      .titleButton {
        display: block;
      }
    }
  }
}

@media (max-width: 1240px){

  .responsiveSearch{
    position: relative;
  }

  .authPlaceholder {
    min-width: 144px;
  }

  .headerContainer {
    &__nav {
      .item {
        .item_text {
          display: none;
        }
      }
    }
  }
}

@media(max-width: 1150px) {
  .header__btnLog{
    min-width: unset;
    width: 220px;
    button {
      font-size: 12px;
      padding: 0 !important;
    }
  }
}

@media(max-width: 1000px){

  .accountSideBar, .termsSidebar {
    top: $header_height2;
    //top: 0 !important;
    height: calc(100% - $header_height2);
    //height: calc(100%) !important;

    .linksBox {
      //top: $header_height2;
      top: 0 !important;
      padding: 20px 8px;
      max-height: calc(100% - $header_height2);
    }
  }

  .responsiveSearch{
    display: none;
  }

  .headerContainer__logo {
    display: none;
  }

  .wrapperLogoBg {
    display: none;
  }

  .middleLogoWrapper {
    display: none;
  }

  .columnSideBar {
    display: none;
  }

  .overlay.active {
    left: 0;
  }

  .mobilePromo__wrapper {
    display: flex;
  }

  .sidebar__socialBlock{
    justify-content: center;
  }

  .accountSideBar, .termsSidebar {
    &.active {
      transform: translateX(0);
    }
  }

  .headerContainerWrapper {
    margin-left: 0;
    //height: 56px;

    .wrapperNavBar {
      height: 56px;
    }
  }

  .columnFields {
    &:first-child {
      margin-bottom: 0 !important;
    }
  }

  .headerContainer {

    &__buttons {
      display: none;
    }

    .nav_desktop {
      display: none;
    }

    .nav_mobile {
      display: flex;
      background: none !important;
      border: none !important;
      box-shadow: none !important;

      .active_link {
        background: none !important;
        box-shadow: none !important;
        color: var(--theme-primary-yellow) !important;

      }
    }

    &__inner {
      .flex_left {
        .resp_burger {
          display: none;
        }
      }
    }
  }

  .sidebar {
    &__btnLog {
      display: none;
    }
  }

  .adventureCandyCrash{
    display: flex;
    align-items: center;
    justify-content: center;
  }


}

@media(max-width: 1070px) {


  .sidebar__seeProfile {
    display: block;
    align-items: center;
    font-size: 12px;
    margin: auto;
    margin-right: 32px;
    color: $spins-primary-color;
  }

}

@media(max-width: 985px) {
  .headerContainer {
    &__buttons {
      .sidebar__btnLog {
        button {
          min-height: 40px !important;
          max-height: unset !important;
          font-size: 12px;
        }
      }
    }
  }
}

@media(max-width: 900px) {
  .headerContainer {
    &__buttons {
      .sidebar__btnLog {
        button {
          min-height: 40px !important;
          max-height: unset !important;
          font-size: 12px;
          padding: 0 20px;
        }
      }
    }
  }
}

@media(max-width: 890px){

  //.middleLogoWrapper svg {
  //  width: 200px;
  //}
}

@media(max-width:850px){

  .middleLogoWrapper{
    display: none;
  }

  .middleLogoWrapper {
    display: none;
  }

  .accountSideBar, .termsSidebar {
    top: $header_height2;
    height: calc(100% - $header_height2);
  }

}

@media(max-width:767px) {
  .headerContainer__inner {
    min-height: 56px;
  }

  .headerContainer__logoOut {
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    right: auto;

    img {
      width: 78px;
      height: 40px;
    }
  }
}

@media(max-width: 700px){
  .headerContainer__nav {
    display: none;
  }
}

@media (max-width: 550px) {
  //.sidebar {
  //  width: 100%;
  //}
  .headerContainer {
    min-height: 0px;
  }
}

@media (max-width: 425px){
  //.logoMobileDarkMode{
  //  display: block;
  //}
  //.bgLogoMobileDarkMode {
  //  display: block;
  //}
  .sidebar {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .accountSideBar, .termsSidebar {
    min-width: 100vw !important;
    width: 100vw !important;
    //height: 100vh;
  }

  .adventureCandyCrash__wrapper {
    padding: 6px 20px;
  }

  .termsSidebar .active, .accountSideBar .active {
    //height: 100vh;
  }
}

@media (max-width: 360px){




}
