@import "mixins";


.hoverEffect {
  display: none;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
}

.topGamesSlider {
  margin-bottom: 0!important;

&__box {
  display: flex;
  position: relative;
  z-index: 50;
  margin-bottom: 18px;
  justify-content: space-between;
  @include fullWidthElementsPadding;
}


  &__titleBlock {
    display: flex;
    justify-content: space-between;
  }

  &__pages {

      width: 46px;
      height: 26px;
      background: #EEB665;
      border: 8px solid #1B1025;
      border-radius: 20px;
  }

  &__card {}

  //&__block {}

  &__wrapper {
    position: relative;
    padding-left: 140px;



      &:hover {

        .topGamesSlider__index {


          svg{
            transform: translateX(50px) translateY(25px)!important;
            -webkit-transform: translateX(10px) translateY(25px);
            opacity: 0.5;
          }

        }
        .topGamesSlider__card {
          transform: scale(1.0,1);
          -webkit-transform: scale(1.0,1);
        }
      }
  }

  &__index {
    svg {
      margin-left: -30px;
      //font-size: 310px;
      line-height: 1;
      font-weight: 900;
      color: #FBB90E !important;
      position: absolute !important;
      z-index: 5 !important;
      bottom: -40px !important;
      left: 50% !important;
      transform: translateX(-25%) scale(1) !important;
      -webkit-transform: translateX(-25%) scale(1) !important;
      transform-origin: bottom;
      -webkit-transform-origin: bottom;
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      pointer-events: none;

      &:first-child {
        //margin-left: 0;
      }
    }


    //text {
    //  font-size: 72px;
    //  transition: all 0.3s linear;
    //  -webkit-transition: all 0.3s linear;
    //  font-family: 'sans-serif';
    //}

    //UNDO start


    //UNDO end
  }


  &__block {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    border-radius: 10px;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    position: relative;

    &:hover {
      .hoverEffect {
        display: block;
        width: 230px;
        height: 330px;
        border-radius: 10px;
        position: absolute;
        transition: all .3s ease;
        -webkit-transition: all .3s ease;
        background: linear-gradient(0deg, rgba(25, 29, 38, 0.70) 0%, rgba(25, 29, 38, 0.70) 100%);
        z-index: 0;
      }
      //transition: all .3s ease;
      //-webkit-transition: all .3s ease;
      //background: linear-gradient(0deg, rgba(25, 29, 38, 0.70) 0%, rgba(25, 29, 38, 0.70) 100%);

      .topGamesSlider__index {

        svg {
          bottom: -20px !important;
          width: 140px;
          height: 140px;
          transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          margin-left: -40px;
        }

      }
    }
  }

  &__swiper_item {
    width: auto !important;
    height: auto !important;

    &:first-child{
      //padding-left: 65px!important;
      @include sliderPaddingLeft;
    }
  }

}


.swiper_top_games {

}

.themes__mySwiper {
  padding-bottom: 50px;
}



.hover {
  .topGamesSlider__index {
    svg {
      transform: translateX(20px) translateY(25px);
      -webkit-transform: translateX(20px) translateY(25px);

    }
  }

}
@media (max-width: 1200px) {

  .hover {
    //.topGamesSlider__index {
    //  transform: translateX(0px);
    //  -webkit-transform: translateX(0px);
    //}
  }

}

@media (max-width: 991px) {

  .topGamesSlider__block {
    margin-right: 0px;
    width: 100px!important;
    //padding-left: 30px;
    margin-bottom: 30px;
    z-index: 999;
    padding-left: 0;

  }


  .themes__mySwiper {
    padding-bottom: 0;
  }

  //.topGamesSlider__index {
  //  display: none;
  //}

  .topGamesSlider__index {
    //font-size: 130px;
    //font-size: 100px;
    //text-stroke: 4px #444242;
    //-webkit-text-stroke: 2px #444242;
    //letter-spacing: -22px;
    z-index: 9;
    margin-bottom: -120px;
    //margin-left: -72px;
    //margin-right: -50px;
    pointer-events: none;
    transform: translate(50px, 10px) scale(0.5);

    //&.last {
    //  margin-left: -125px;
    //  margin-right: -90px;
    //}

  }

  .topGamesSlider__card {
    transform: translateX(-3);
    -webkit-transform: translateX(-3px);


    max-width: 100px;
  }


  .hoverEffect{
    display: none!important;
  }

}