@import 'variables';
@import "mixins";



.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //align-items: flex-start;
  //-webkit-align-items: flex-start;
  //border: 1px solid #35303e;
  min-height: 56px;
  padding: 8px 16px;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  cursor: pointer;
  user-select: none;
  position: relative;
  width: 100%;
  margin-right: 10px;
  border-radius: 30px;
  border: 1px solid  $spins_border_gray_color;
  background: var(--Site-BG, #15191E);
  box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.15) inset, 0px -2px 1px 0px rgba(255, 255, 255, 0.10) inset;
  align-items: center;
  -webkit-align-items: center;
  resize: none;

  &.noShadow {
    box-shadow: unset;
  }

  &:last-child {
    margin-right: 0;
    padding: 8px 16px;
  }

  &.hasIcon {
    padding-left: 8px;
  }

  &__inner {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    width: 100%;
    justify-content: flex-start;
    text-align: left;
    overflow: hidden;
    position: relative;

    &__icon {
      //margin-right: 8px;

      padding-top: 10px;
      position: absolute;
      top: -3px;
      left: -11px;
    }
  }

  &__labelWrapper {
    display: flex;
    align-items: flex-end;
    -webkit-align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    //position: relative;
  }

  &__selected {
    text-transform: capitalize;
    margin-right: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--White, var(--Text-Primary, #FFF));
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  &__icon {
    margin-right: 12px;
    color: $primary-color;
  }

  &__label {
    text-transform: capitalize;
    font-size: 12px;
    color: #929396;
    font-weight: 400;
    letter-spacing: 0;
    margin-left: -25px;
  }

  &__caret {
    position: absolute;
    top: 4%;
    right: -11px;
    width: 37px;
    height: 36px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: grey;
    transform: rotate(90deg);



    & svg {
      width: 24px;
      height: 18px;
    }

    //opacity: .5;
    //transform: rotate(0);
    //-webkit-transform: rotate(0);
    //transition: all .3s ease;
    //-webkit-transition: all .3s ease;
    //position: absolute;
    //right: 0;
    //top: -8px;

  }

  &__options {
    position: fixed;
    z-index: 1100;
    min-width: 250px;
    background-color: #191D26;
    border-radius: 14px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    padding: 8px;
    padding-bottom: 0;
    padding-left: 0;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    max-height: 300px;
    overflow: auto;
    padding-right: 0;

    &__item {
      cursor: pointer;
      height: 100%;
      transition: all .3s;
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: content-box;
      padding: 8px 0;
      display: flex;
      align-items: center;
      width: 100%;
      color:  #929396;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;

      &__icon {
        //margin-right: 8px;
      }

       &:hover {
        background: rgba(0, 0, 0, 0.20);
      }

      &.selected {
        color: $spins-primary-color;
      }
    }
  }

  &.active {
    .root__caret {
      opacity: 1;
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
    }

    .root__options {
      opacity: 1;
      transform: translateY(0);
      -webkit-transform: translateY(0);
      pointer-events: initial;
      z-index: 99999999;
    }
  }

  &:hover, &.active {
    border-color: $spins_border_gray_color_hover;
  }
}

.wrapperBlock {
 padding-left: 35px;
}

.closeModal {
  display: none;
}

.depositBgStyleDropdownBonus{
  .wrapperBlock {
    padding-left: 0;
  }
  .root__label{
    margin-left: 0;
  }
  .root__selected {
    font-size: 12px;
    margin-top: 3px;
  }

}

.item_wrapper {
  display: flex;
  align-items: center;

  .label {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}

.item_wrapper_sublabel{
  margin-top: 5px;
  padding-bottom: 7px;
}

.root__options__item__wrapper{
  display: flex;
}

.wrapperSubtitle{
  margin-top: -12px;
}

@media (max-width: 767px)  {
  .root {
    //margin-bottom: 10px;
    min-height: 50px;

  }
}


@media (max-width: 550px)  {

  .root {
    &__options {
      &.bottom_options {
        bottom: 0 !important;
        top: unset !important;
        left: unset !important;
        width: 100% !important;
        //height: 200px;
        border-radius: 20px 20px 0 0 !important;
      }
    }
  }

  .portal {
    &.deposit_portal {
      background: rgba(0, 0, 0, 0.4);
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
    }
  }
}