@import 'variables';
@import "mixins";

.search_icon {
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
}

.overlay {
  position: fixed;
  display: flex;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  overflow: hidden;
  pointer-events: none;
  padding-bottom: 30px;
  background-color: #15191E;
  transform: translateY(50px);
  -webkit-transform: translateY(50px);
  opacity: 0;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  //padding-top: 30px;


  &__empty {
    font-weight: 500;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  &__results {
    overflow-y: auto;
    height: 100%;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .grid {
      @include pageContainerSidesPadding;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    & > div {
      display: flex;
      //height: 100%;
      height: auto;

    }
  }

  & > * {
    width: 100%;
  }

  &.active {
    pointer-events: initial;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    opacity: 1;
    transition: all .3s linear;
    -webkit-transition: all .3s linear;

    &::before {
      transform: translate(-50%, -50%) scale(999);
      -webkit-transform: translate(-50%, -50%) scale(999);
    }
  }
}

.search__iconResponsive{
  position: absolute;
  right: 15px;
  bottom: 7px;
}

.search_bar {
  margin-top: 1px;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  left: 0;
  right: 0;
  height: 115px;
 -webkit-transition: all .3s linear;
  display: flex;
  flex-direction: column-reverse;
  pointer-events: none;

  .search_bar__inner {
    transition: all .3s linear;
    -webkit-transition: all .3s linear;
    margin: 0 auto;
    pointer-events: none;
  }

  * {
    cursor: default;
  }

  &.active {
    opacity: 1;
    pointer-events: initial;
    //margin-left: -74px;
    display: flex;
    transition: all .3s linear;
    -webkit-transition: all .3s linear;

    .search_bar__inner {
      pointer-events: initial;
    }

    .search__furnizorWrapper, .search__sortWrapper {
      * {
        cursor: pointer;
      }
    }

    button {
      cursor: pointer;

      * {
        cursor: pointer;
      }
    }

    * {
      cursor: initial;
    }

    &__filterIcon {
      cursor: pointer !important;
    }
  }

  &__inner {
    @include pageContainerSidesPadding;
    padding-bottom: 12px;
    max-width: 1920px;
    width:100%;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    transition: all .3s linear;
    -webkit-transition: all .3s linear;
    z-index: 5;
    height: fit-content;
    gap: 12px;


    .outlinedButton {
      height: 40px;
      //padding: 0 32px;
      cursor: pointer;
      text-transform: capitalize;
      display: inline-flex;
      align-items: center;
      -webkit-align-items: center;
      transition: all .3s ease;
      outline: none;
      -webkit-transition: all .3s ease;
      text-align: center;
      justify-content: center;
      background: none;
      margin-right: 16px;
      border: 2px solid  transparent;
      color:  #15191E;
      font-size: 14px;
      font-style: normal;
      min-width: 157px;
      font-weight: 600;
      line-height: 16px;

      border-radius: 30px;
      background: var(--Primary-Yellow, #FBB90E);
      box-shadow: 0px 4px 8px 0px rgba(255, 255, 255, 0.60) inset, 0px -4px 8px 0px rgba(0, 0, 0, 0.40) inset;

      &:hover {
        border: 2px solid  #FBB90E;
        background: transparent;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
        color: white;

      }
    }

    .filters {
      gap: 12px;

      .right {
        display: none;
      }

      button {
        min-height: 40px;
      }
    }
  }

  &__text {
    color:  #FFF;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }

  &__back {
    //padding: 0;
    border: 0;
    outline: none;
    background-color: transparent;
    color: $primary-color;
    border-radius: 40px;
    opacity: 0.5;
    background: var(--Stroke, #2D3543);
    box-shadow: 0px 4px 8px 0px rgba(255, 255, 255, 0.10) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.20);
    min-width: 40px;
    height: 40px;
    margin-right: 16px;


  }

  &__input {
    border: 0;
    outline: none;
    font-size: 18px;
    flex: 1;
    height: 100%;
    padding: 0;
    background-color: transparent;
    color: #fff;

    &::-webkit-input-placeholder {
      font-size: 16px;
      font-weight: 500;
      color: $dark_text;
    }
    &::-moz-placeholder {
      font-size: 16px;
      font-weight: 500;
      color: $dark_text;
    }
    &:-ms-input-placeholder {
      font-size: 16px;
      font-weight: 500;
      color: $dark_text;
    }
    &:-moz-placeholder {
      font-size: 16px;
      font-weight: 500;
      color: $dark_text;
    }
  }

  &__providers {
    justify-content: center;
    padding: 0px 8px;
    padding-right: 0px;

    &__item {
      max-width: 110px;
      //width: 100px;
      width: 30%;
      margin-bottom: 10px;
      height: 60px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      -webkit-align-items: center;
      cursor: pointer;
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      margin-right: 8px;
      border-radius: 8px;



      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: 76.5px;
        height: 36px;
        transition: all .3s ease;
        -webkit-transition: all .3s ease;
      }

      &:hover {
        border-color: $spins-primary-color!important;
      }

      &.active {
        //border: 1px solid  $spins-primary-color;
      }
    }
  }

  &__selectedBlock {
    text-align: end;
    padding:8px 50px 8px 0;
    background: #19131D;
    border-top: 1px solid #27222D;
    margin: 5px -50px 0 -50px;

  }

  &__reset {
    cursor: pointer;
    background: transparent;
    border: none;
    //color: white;
    padding: 0 21px 8px 15px;
    //height: 36px;
    //padding: 4px 12px;
    //background: #2D3543;
    //border-radius: 20px 20px 0 0 ;
  }

  &__selectedGames {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #84789C;
    margin-right: 24px;

  }

  &__title {
    color: #fff;
    margin: 0 0 16px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }

  &__furnizorBlock {
    width: 360px;
    position: absolute;
    overflow-y: scroll;
    max-height: 70vh;
    left: 70%;
    top: 205px;
    padding: 16px 8px;
    z-index: 100;
    padding-right: 0;
    border-radius: 20px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
   }

  &__reset{
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    //color: #FFF;
    align-items: center;
    -webkit-align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }


}

.search__furnizorWrapper{
  width: 172px;
}

.search__sortWrapper {
  width: 235px;
}

.search__sideBar {
  display: none;
}

.search_bar__filterIcon {
  display: none;
}

//@media (max-width: 1440px){
//  .search_bar.active{
//    height: 162px;
//  }
//}

@media (max-width: 1300px) {
  .search_bar {
    &__inner {
      .filters {
        button {
          font-size: 12px;
          white-space: nowrap;
          padding: 0 12px;
        }
      }
    }
  }
}

@media (max-width: 1240px) {
  .search_bar__inner {
    //padding-left: 42px;
  }

  .search_bar__text{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .search_bar__back {
    width: 40px;
    height: 40px;
    padding: 7px;
  }
}

@media (max-width: 1200px) {

  .search_icon {
    position: absolute;
    right: -41px;
    z-index: 7;
  }

  .search__furnizorWrapper, .search__sortWrapper{
    display: none;
  }

  .search_bar__furnizorBlock {
    display: none;
  }

  .searchOverlay{
    position: fixed;
    width: 100%;
    height: 100%;
    left:0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;


    &.active{
      opacity: 1;
      transition: all .3s ease;
      -webkit-transition: all .3s ease;
      pointer-events: initial;
    }
  }

  .header-scroll.active-search:not(.fixed-scroll):after {
    height: 0;
  }

  .overlay__results > div {
    padding-left: 100px;
  }

  .header-scroll:after {
    display: none;
  }

  .search_bar__selectedBlock {
    display: none;
  }

  .search_bar__selectedProvider {
    background: #E02020;
    color: white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    padding: 0 3px;
    font-size: 10px;
    position: absolute;
    left: 29px;
    top: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search_bar__inner {
    margin-top: 17px;
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 12px;

    .filters {
      .left {
        display: flex;
        flex-wrap: nowrap;
        gap: 16px;
      }
    }
  }

  .search_bar__filterWrapper__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    align-items: center;
    margin: 12px 0;
    color:  #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;

  }

  .search_bar {
    &.active {
      position: absolute;
      z-index: 10;
      left: 0;
      right: 0;
      //height: 143px;
      margin-left: 0;
    }
  }

  .search__sideBar {
    display: block;
    //border: 1px solid  #2D3543;
    background:  #212632;
    height: 100%;
    max-width: 395px;
    width: 100%;
    position: fixed;
    top: 0px;
    right: -110%;
    z-index: 12;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    //height: 100%;
    padding: 24px 16px 10px;

    &.active {
      transition: all .3s ease;
      -webkit-transition: all .3s ease;
      right: 0;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }

  &__filterText {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__text {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 0;
    color: #FFFFFF;
  }
    &__textIcon {
      width: 40px;
      height: 40px;
      padding: 8px;
      position: relative;
      border-radius: 40px;
      background: var(--Stroke, #2D3543);
      box-shadow: 0px 4px 8px 0px rgba(255, 255, 255, 0.10) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.20);

      & img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

      }
    }
}

  .search__sideBar__text{
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  .search__sideBar__textIcon {

  }

  .search_bar__filterIcon {
    position: relative;
    cursor: pointer;
    //margin-right: 15px;
    border-radius: 20px;
    border: 1px solid var(--Stroke, #2D3543);
    background: var(--Site-BG, #15191E);
    box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.15) inset, 0px -2px 1px 0px rgba(255, 255, 255, 0.10) inset;
    width: 110px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;


    .svg-icon {
      color: grey;
      path{
        fill: grey;
      }
    }
  }

  .sideBarSort {
    display: block;
    }
}

@media (max-width: 1000px) {
  .search_bar {
    height: 72px;
    top: 0;
    margin-top: 0;

    &.active{
      margin-left: 0;
    }

  }

  .overlay__results > div {
    padding-left: 0;
  }

  .overlay {
    margin-left: 0;
  }

}

@media(max-width: 757px) {
  .search_bar {

    &.active {
      height: 120px !important;
    }

    &__inner {
      flex-direction: column-reverse;
      gap: 0;

      .search_bar__back {
        display: none;
      }

      .filters {
        width: 100%;
        margin: 12px 0 0 0;
        gap: 12px;
        display: flex;
        justify-content: space-between;

        .right {
          display: block;

          .back_btn {
            min-width: 40px;
            min-height: 40px;
            border-radius: 100px;
            background: none;
            border: none;
            padding: 0;
          }
        }

        button {
          min-height: 40px !important;
          height: 40px !important;
          font-size: 12px;
          padding: 0 16px;
        }
      }
    }
  }
}

@media(max-width: 550px) {

  .search_bar__filterIcon{
    width: 130px;
  }

  .search_bar {

    &.active {
      height: 125px;

    }

    &__inner {

      .outlinedButton {
        font-size: 12px;
        padding: 0 !important;

        &:focus {
          border: none;
        }

        &:hover {
          border: none;
        }
      }
    }

  }
}

@media(max-width: 510px) {
  .search_bar__filterIcon {
    width: 59px;
  }
}

@media(max-width: 475px) {
  //.search_bar__filterIcon {
  //  width: 59px;
  //}
  .search_bar__inner .outlinedButton{
    min-width: 120px;
  }
  .search_bar__inner {
    padding-left: 15px;
  }
}

@media(max-width: 370px) {
  .search_bar {

    //&.active {
    //  height: 60px;
    //}
    &__inner {

      .outlinedButton {
        font-size: 10px;
        //height: 30px;
        min-width: fit-content;
        padding: 0 !important;


      }
    }

    &__input {
      width: 190px;

    }
  }
  .search_bar__filterIcon {
    width: 52px;
    padding: 0 12px;
  }
}