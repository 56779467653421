@import "variables";

$sideWidth: 153px;

.main {
  width: 315px;
  height: 40px;
  //background: $background-color;
  //border: 1px solid $dark2;
  //border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 20px;
  //border: 1px solid  #2D3543;
  //background: #15191E;
  //box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.15) inset, 0px -2px 1px 0px rgba(255, 255, 255, 0.10) inset;

  .sides {
    width: $sideWidth;
    text-align: center;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 16px;
    text-transform: capitalize;
    color: #929396;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &.active {
      color: #fff;
      border-radius: 20px;
      background: #2D3543;
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.20), 0px 4px 8px 0px rgba(255, 255, 255, 0.10) inset;
    }
  }



  .selector {
    border-radius: 100px;
    width: 50%;
    height: 100%;
    //border: 1px solid $primary-color;
    position: absolute;
    left: 0;
    -webkit-transition: 0.3s all;
    transition: 0.3s all;

    &.floated {
      -webkit-transition: 0.3s all;
      transition: 0.3s all;
      left: calc(100% - $sideWidth);
    }
  }
}

@media (max-width: 767px){

}

@media (max-width: 575px) {

  .main {
    width: 100%;
    height: 40px;
    background: $background-color;
    border: 1px solid $dark2;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: relative;

    .sides {
      //width: 50%;
      text-align: center;
      font-size: 16px;
      font-family: Poppins;
      font-weight: 600;
      line-height: 16px;
      text-transform: uppercase;
      cursor: pointer;

      &.active {
        color: #fff;
      }
    }

    .selector {
      border-radius: 100px;
      width: 50%;
      height: 100%;
      //border: 1px solid $primary-color;
      position: absolute;
      left: 0;
      -webkit-transition: 0.3s all;
      transition: 0.3s all;

      &.floated {
        -webkit-transition: 0.3s all;
        transition: 0.3s all;
        left: 50%;
      }
    }
  }

  .toggleButtonsCasino {
    flex: 1;
  }
}

