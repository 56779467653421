@import "variables";
@import "mixins";

//.support_page {
//  @include paginationBottomMargin;
//}

.allProviders {

  &__title {
    text-align: center;
    font-size: 3rem;
  }

  &__provider_block {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    text-align: center;
    padding-bottom: 100px;
  }

  &__provider_item {
    width: 11.5%;
    padding: 4px 8px;
    border-radius: 8px;
    transition: 0.2s all;
    -webkit-transition: 0.2s all;

    a {
      width: 100%;
      transition: 0.2s all;
      -webkit-transition: 0.2s all;

      img {
        width: 100%;
        object-fit: contain;
        transition: 0.2s all;
        -webkit-transition: 0.2s all;
      }
    }
  }

}

@media (max-width: 1442px) {
  .allProviders {
    &__provider_item {
      width: 14%;
    }
  }
}

@media (max-width: 1100px) {
  .allProviders {
    &__provider_item {
      width: 18%;
    }
  }
}

@media (max-width: 850px) {
  .allProviders {
    &__provider_item {
      width: 23%;
    }
  }
}

@media (max-width: 850px) {
  .allProviders {
    &__provider_item {
      width: 23%;
    }

    &__title {
      margin-top: 15px;
    }
  }
}

@media (max-width: 575px) {
  .allProviders {

    &__title {
      font-size: 24px;
    }

    &__provider_item {
      width: 30%;
    }
  }
}

@media (max-width: 420px) {
  .allProviders {
    &__provider_item {
      width: 45%;
    }
  }
}