@import 'variables';
@import "mixins";

.builder_main {
  padding-bottom: 100px !important;
}

.main {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 100%;
  gap: 64px;
  padding-bottom: 50px !important;

  .mobile_hero {
    .title {
      display: none;

      @media (max-width: 850px) {
        display: block;
      }
    }
  }

  .hero {
    position: relative;

    .title {
      position: absolute;
      color: #fff;
      left: 100px;
      top: 50%;
      max-width: 300px;
      text-align: start;
      font-weight: 600;
      font-size: 42px;
      transform: translateY(-50%);

      @media (max-width: 1500px) {
        max-width: 250px;
        font-size: 32px;
      }

      @media (max-width: 1450px) {
        max-width: 300px;
        font-size: 42px;
      }

      @media (max-width: 1200px) {
        max-width: 250px;
        font-size: 32px;
      }

      @media (max-width: 850px) {
        display: none;
      }
    }
  }

  &__image {
    border-radius: 30px;
  }

  &__mobile_image {
    border-radius: 30px;
    display: none;
  }

  &__text_content {
    //max-width: 960px;
    margin: 24px auto 0;

    .terms_btn {
      text-align: center;
      background: none;
      border: none;
      display: flex;
      gap: 10px;

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        text-transform: uppercase;
        color: $spins-dark_text;
      }

      svg {
        transition: 0.2s all;
        -webkit-transition: 0.2s all;
        transform: rotate(270deg);
        width: 24px;
        height: 24px;
      }

      &.active {
        svg {
          transform: rotate(90deg);
        }
      }

    }
  }

  &__promo_block {
    height: fit-content;
  }

  &__controllers {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    margin-top: 30px;
  }
}


.promotionsAside {
  min-height: 300px;
  width: fit-content;
  padding: 8px 24px;
  overflow-y: scroll;
  max-width: 368px;
  border-radius: 30px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__title_container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 10px;
  }

  h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #FFFFFF;
  }

  .mobileSlider {
    margin-top: 10px;
    overflow-y: scroll;
    padding-bottom: 100px;
    //max-height: 700px;
    //z-index: 100000000;
    //border: 1px solid #fff;

    &::-webkit-scrollbar {
      display: none;
    }

    .oldPromotionContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-bottom: 50px;
      height: fit-content;

    }
  }

  &__divider {
    width: 100%;
    height: 1px;
  }
}

.promotionsSlider {
  color: #fff;
  min-height: 480px;
  width: calc(100% );
  margin-top: 50px;



  &:hover {
    .promotionsSlider__arrow_container {
      opacity: 1;
    }
  }

  &.oldProms {
    display: none;
  }

  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
  }

  &__slider {
    width: unset !important;
    margin-left: 0 !important;
    @include sliderNoMarginRight
  }

  &__slide {
    max-width: 300px;
  }

  .promotionItem {
    width: 440px;
    height: 450px;
  }

  &__slider_header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: 40px !important;
    font-weight: 600;
  }

  &__arrow_container {
    opacity: 0;
    transition: 0.3s all;
    -webkit-transition: 0.3s all;
  }

}

.promotionItem {
  min-height: 480px;
  background: #1F1B24;
  border-radius: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  img {
    height: 280px;
    width: 100%;
    border: none;
    object-fit: cover;

  }

  .bottomContainer {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 20px;

    h3 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
    }

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
    }

    .button {
      width: 100%;
      margin-top: auto;
    }
  }
}

.slidersSection {
  position: relative;
  min-height: 630px;
  transform: translateY(-35px);
  order: 2;

  &__bg {
    background: $background-color;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    -webkit-box-shadow: 0px -20px 15px -4px rgba(154, 119, 69, 0.12);
    box-shadow: 0px -20px 15px -4px rgba(154, 119, 69, 0.12);
  }
}

.divider {
  width: 100vw;
  height: 20px;
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  background: $background-color;
  box-shadow: 0px -25px 16px 0px rgba(154, 119, 69, 0.05), 0px -10px 10px 0px rgba(154, 119, 69, 0.08);
}

.divider_container {
  position: relative;
  height: 20px;
  overflow: visible;
  //border: 1px solid #fff;
  width: 100%;

  .divider_bottom {
    position: absolute;
    width: 100vw;
    margin-top: 20px;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: $background-color;
    box-shadow: 0px 25px 16px 0px rgba(154, 119, 69, 0.05), 0px 10px 10px 0px rgba(154, 119, 69, 0.08);
  }
}


@media (max-width: 1900px) {

  .promotionsAside {
    right: 0;
    //transform: translateX(-30px);
    .mobileSlider {
      .oldPromotionContainer {
        .oldPromotion {
          .bottomContainer {
            p {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1730px) {
  .promotionsAside {
    right: 0;
  }
  .promotionItem {
    width: 350px !important;
  }

  .slidersSection {
    position: relative;
    min-height: 630px;
    transform: translateY(-35px);

  }

}

@media (max-width: 1450px) {

  .slidersSection {
    height: 1100px;

    .oldProms {
      top: 30px;

    }

    .expiredProms {
      top: 510px;
    }
  }

  .promotionsSlider {
    &.oldProms {
      display: block;
      margin-top: 0 !important;
    }

    &.expiredProms {
      margin-bottom: 50px;
    }
  }

  .promotionsAside {
    display: none;

  }

  .promotionItem {
    width: 350px !important;
    min-height: 430px;

    img {
      height: 240px;
      width: 100%;
      border: none;
      object-fit: cover;
    }

    .bottomContainer {
      justify-content: start;


      h3 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .main {

    &__text_content {
      margin: 24px auto 0;
    }
  }
}

@media (max-width: 850px) {
  .main {
    &__image {
      display: none;
    }

    &__mobile_image {
      display: block;
    }

    &__controllers {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;

    }

    &__text_content {
      .terms_btn {
        margin: 12px auto;
      }
    }
  }

  .promotionsSlider {
    &__arrow_container {
      display: none;
    }

    &__title {
      font-size: 33px !important;
      font-weight: 600;
    }
  }
}

@media (max-width: 700px) {

  .promotionItem {
    width: 300px !important;
    min-height: 390px;

    img {
      height: 240px;
      width: 100%;
      border: none;
      object-fit: cover;
    }

    .bottomContainer {
      justify-content: start;


      h3 {
        font-size: 16px;
      }

      p {
        font-size: 12px;
      }

      .button {
        font-size: 12px !important;
        min-height: unset;
        padding: 12px;
      }
    }
  }
}

@media (max-width: 400px) {
  .promotionsSlider {
    &__title {
      font-size: 20px !important;
    }
  }
}