@import 'variables';
@import "mixins";

.categoryGames {
  //border: 2px solid red;
}
.sliderContainer {
  overflow-x: scroll;

  .slider  {
    @include mainRightMargin;
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin-top: 30px;

  }

  &::-webkit-scrollbar {
    display: none;
  }
}


.arrowLeft {
  transform: rotate(180deg);
  margin-left: 8px;
}

.skeletonGameCard {
  background: #383838;
  border-radius: 5px;
  margin-right: 20px;
  width: 240px;
  height: 358px;
  transform: scale(0.95);
  display: block;
}

.no_padding_left {
  padding-left: 0 !important;
}

@media (max-width: 991px) {
  .skeletonGameCard {
    background: #383838;
    border-radius: 5px;
    width: 133px;
    height: 212px;
    display: block;
  }
}