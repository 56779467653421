.root {
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  margin-bottom: 16px;

  &__selector {
    width: 87px;
    padding-right: 30px;
  }

  &__input {
    width: 100%;
    margin: 0 !important;
  }
}

.arrowBlock {
  position: absolute;
  top: 50%;
  right: 21px;
  transform: translatey(-50%);

  svg {
    width: 20px;
    height: 27px;
    transform: rotate(90deg);
  }

}