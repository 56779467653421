@import 'variables';
@import "mixins";

.modal {
  width: 450px !important;

  .countdown {
    top: 0;
    left: 0;
    border-radius: 0 0 16px 0;
    position: absolute;
    @include smallCountdown;

  }

  .inner {
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    overflow: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .title {
      font-size: 18px;
      text-align: start;
      color: var(--theme-white-black-text);
    }

    .submit_prizes {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      .prize_img {
        width: 200px;
        //margin-top: 10px;
      }

      .prize_name {
        padding: 8px 34px;
        font-size: 24px;
        color: var(--theme-primary-yellow );
        font-weight: 700;
        border-bottom: 1px solid var(--theme-column-color);
        border-top: 1px solid var(--theme-column-color);
      }

      .tax_content {
        text-align: start;
        color: var(--theme-column-color);

        svg {
          margin-right: 8px;
        }
      }

      .controllers {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        button {
          min-width: 300px;
        }

        .continue {
          width: fit-content;
          min-width: unset;
          background: none;
          border: none;
          color: var(--theme-primary-yellow);
          font-weight: 700;
          text-decoration: underline;
        }
        //max-width: 250px;
      }

      @media (max-width: 500px) {
        gap: 16px;

        .prize_name {
          font-size: 18px;
        }

        .prize_img {
          width: 120px;
        }

        .tax_content {
          p {
            font-size: 12px;
            margin-bottom: 5px;

          }
        }
      }
    }

    .description {
      text-align: start;
      color: var(--theme-column-color);
      font-weight: 700;
      font-size: 14px;
    }

    .prizes_type {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .variant {
        min-height: 50px;
        border: 1px solid transparent;
        cursor: pointer;
        border-radius: 16px;
        transition: 0.2s all;
        display: flex;
        gap: 16px;
        padding: 16px;
        position: relative;
        overflow: hidden;
        border: 1px solid var(--theme-bg2-hover);
        align-items: center;

        &.best_offer_variant {
          padding-top: 20px;
        }

        .best_offer {
          position: absolute;
          right: 0;
          top: 0;
          background: $info;
          padding: 4px 12px;
          font-weight: 700;
          border-radius: 0 0 0 16px;
          color: #fff;
          font-size: 12px;

        }

        .radio {
          min-width: 24px;
          width: 24px;
          height: 24px;
          border: 2px solid var(--theme-column-color);
          border-radius: 100px;
          position: relative;
        }

        img {
          width: 90px;
        }

        .inf {
          display: flex;
          flex-direction: column;
          align-items: start;

          span {
            font-size: 18px;
            font-weight: 700;
          }

          .inf_title {
            color: var(--theme-white-black-text);

          }

          .inf_decription {
            color: var(--theme-primary-yellow);
          }
        }

        &.selected, &:hover {
          opacity: 1;
          border: 1px solid var(--theme-primary-yellow);
        }

        &.selected {
          .radio {
            //background: $primary-color;
            border: 2px solid var(--theme-primary-yellow);

            &::before {
              content: "";
              width: 14px;
              height: 14px;
              border-radius: 100px;
              position: absolute;
              background: var(--theme-primary-yellow);
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
        
        @media (max-width: 500px) {

          gap: 8px;

          img {
            width: 70px !important;
          }

          .best_offer {
            font-size: 10px;
          }

          .inf {
            span {
              font-size: 14px;
            }
          }
        }
      }
    }

    button {
      width: fit-content;
      margin: 0 auto;
    }

    .btn_claim {

    }

    .loader {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--theme-var-bg-main-transparent);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 573px) {
    position: fixed !important;
    bottom: 0 !important;
    min-width: 100vw !important;
    border-radius: 16px 16px 0 0 !important;
    padding: 12px;

    .inner {
      .prizes_type {
        .variant {
          padding: 12px;

          img {
            width: 80px;
          }
        }
      }
    }
  }
}


.details_modal {

  .inner {
    margin-top: 20px;


    .details {
      color: var(--theme-column-color);
      text-align: start;

      p {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  @media (max-width: 500px) {
    padding: 16px !important;

    .inner {

      .details {

        ul, ol {
          padding-left: 18px;
        }

        p {
          font-size: 12px;

        }
      }
    }
  }
}