@import 'variables';
@import "mixins";

.root__block {
  display: none;
}

.root {
  //border: 20px solid #0B3566;
  position: relative;
  margin-bottom: 120px;
  max-height: 480px;

  &__bgImage {
    width: 100%;
    height: 100%;
  }

  & img {
   width: 100%;
    height: 100%;
  }

  &__wrapper {
    display: none;
  }

  &__imageWrapper {
    display: none;
  }
  //&__infoMob {
  //  background-color: $dark1;
  //}

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 0;
    min-height: 72px;
    padding-bottom: 3px;
    max-width: 100%;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    position: absolute;
    max-width: 100%;
    min-width: 400px;
    justify-content: space-between;

    .random_game_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1790px;
      width: 100%;
      @include fullWidthElementsPadding;

      .h5 {
        margin: 0;
      }
    }

    &__title {

    }

    &__game {
      //margin-right: 72px;
      margin-right: auto;
      margin-left: auto;
      display: flex;
      align-items: center;
      -webkit-align-items: center;

      &__provider_thumb {
        height: 48px;
        margin-right: 24px;
      }

      &__provider_name {
        color: $dark_text;
        font-weight: 500;
        font-size: 16px;
        line-height: 1;
        display: block;
      }

      &__name {
        font-weight: 600;
        font-size: 16px;
        line-height: 1;
        color: #fff;
        display: block;
        margin-bottom: 4px;
        max-width: 150px;
      }
    }
  }

  &.run_random_game {
    .root__info {
      //width: 550px;
    }
  }
}

.root__infoMob {
  display: none;
}

//.desctopImg {
//  display: block;
//}

.mobileImg {
  display: none;
}

.mobileTitle {
  display: none;
}

@media (max-width: 1300px) {
  .root {
    &__info {
      .random_game_wrapper {
        .h5 {
          font-size: 32px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .root__info {
    width: 100%;
    border-radius: 0;
    justify-content: center;

  }
}

@media (max-width: 850px) {
  .root {
    &__info {
      .random_game_wrapper {
        h5 {
          font-size: 24px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .root__info {
    padding: 0;
  }
}

@media (max-width: 575px) {
  .root__info__game__provider_thumb {
    margin-right: 10px;
  }

  .desctopImg {
    display: none;
  }

  .mobileImg {
    display: block;
  }

  .mobileTitle {
    display: block;
    margin-bottom: 20px;
    font-size: 16px!important;
    line-height: 16px;
    padding-left: 10px;
  }

  .root__info__title {
    padding-left: 0!important;
  }

  .root__info__game {
    margin-right: 8px;
  }

  .root__btn {
    padding: 10px 15px!important;
  }

  .root__liveImageBlock {
    width: 100%;
    height: 250px;
    margin: auto;
    background-position: center;
    background-size: cover;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  .root{
    margin-bottom: 25px;
  }

  .root__wrapImage {
    display: flex;
    align-items: center;
    width: 48px;
    height: 48px;
    background: black;
    border-radius: 50%;
    padding: 0 2px;

     & img {
       height: auto;
       width: auto;
     }
  }

  .root__image {
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .root__infoMob {
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-align-items: center;
    @include fullWidthElementsPadding;
  }

  .root__secondBlock {
    display: flex;
    justify-content: space-around;
    //width: 100%;

  }

  .root__bgImage {
    display: none;
  }

  .root__block {
    overflow: hidden;
    display: block;
  }

  .root__info {
    display: none;

    &__game__name {
      font-size: 14px;
      max-width: 100%;
    }
    &__game__provider_name {
      font-size: 12px;
    }
    &__title {
      font-size: 18px;
    }
    &__game__provider_thum {
      margin-right: 10px;
    }
  }

  .root__info__game {
    flex-direction: column;
    justify-content: space-around;
    margin-right: 50px;
  }

  .root__wrapper {
    display: flex;
    height: 270px;
    overflow: hidden;
    position: relative;
  }

  .root__scaleBlock {
    filter: blur(5px);
    top: 50%;
    left: 50%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    transform: translate(-50%, -50%) scale(2);
    -webkit-transform: translate(-50%, -50%) scale(2);

    & img {
      width: 100%;
    }
    //scale: 90;
  }

  .root__gameLive {
    margin: 0 15px;
    text-align: center;
    width: 100%;
  }

  .root__backColor {
    background: transparent;
    margin-top: -70px;
    position: relative;
    z-index: 10;
  }

  .root__imageWrapper {
    display: flex;
    width: 165px;
    height: 240px;
    right: 50%;
    position: absolute;
    transform: translate(50%, 6%);

    & img {
      width: 100%;
    }
  }

}

@media (max-width: 470px){
  .root__info__title {
    font-size: 27px!important;
  }
}

@media(max-width: 425px){
  .root__info__title {
    font-size: 20px!important;
  }
}

@media(max-width: 400px) {
  .root__info__game {
    margin-right: 10px;
    margin-left: 20px;
  }

}

@media(max-width: 380px){
  .root__info__title {
    font-size: 18px!important;
  }
}

