@import 'variables';
@import "mixins";


.main {
  max-width: 100%;
  margin: 0 auto ;
  background: transparent;
  //color: white;
  padding-bottom: 100px !important;
  padding-top: 20px !important;
  display: flex;
  @include fullWidthElementsPadding;
  gap: 20px;

  &__heading {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
    min-width: 580px;
  }

  &__content {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.1px;

    p {
      margin: 0;
    }
  }
}

@media (max-width: 1500px) {
  .main {
    width: 100%;
    max-width: unset;
  }
}

@media(max-width: 1440px) {
  .main {
    &__heading {
      font-size: 32px;
      min-width: 520px;
      line-height: 40px;
    }

    &__content {
      font-size: 11px;
    }
  }
}

@media (max-width: 1200px) {
  .main {
    width: 100%;
    max-width: unset;
    flex-direction: column;

    &__heading {
      font-size: 40px;
      line-height: 52px;
      min-width: unset;
    }

    &__content {
      font-size: 12px;

    }
  }
}

@media (max-width: 990px) {
  .main {
    gap: 10px;
    &__heading {
      font-size: 32px;
      line-height: 40px;
    }

    &__content {
      font-size: 12px;

      p {
        //border: 1px solid #fff;
        margin: 0;
      }
    }
  }
}

@media (max-width: 770px) {
  .main {
    &__heading {
      font-size: 28px;
      line-height: 34px;
    }
  }
}


@media (max-width: 450px) {
  .main {
    &__heading {
      font-size: 22px;
      line-height: 28px;
    }
  }
}


