@import 'variables';
@import "mixins";

.swiper {
  position: relative;
  //margin-top: 25px;
  gap: 10px;
  padding-bottom: 20px;
  justify-content: center;
  @include sliderPaddingLeft;
}

.slider {
  position: relative;
  z-index: 5;
  margin-bottom: 10px;


  &__buttons_containert {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    display: none;

    button {
      position: absolute;
      top: 98px;

      &.left {
        left: 0;
        transform:translateY(-50%) translateX(-50%);
      }

      &.right {
        right: 0;
        transform: translateY(-50%) translateX(75%);
      }
    }
  }

  &__innerRelative {
    position: relative;

    &.noMargin {
      margin-top: 0 ;
    }
  }

  &__sliderRelative {
    margin-bottom: 0;
  }

  .swiper-wrapper {
    padding-top: 0 !important;
  }

  &__swiper_item {
    width: fit-content;
    height: 56px;
    justify-content: center;
    align-items: center;
    transition: .3s all ease;
    -webkit-transition: .3s all ease;
    box-shadow: none;
    border-radius: 100px;
    margin-right: 10px !important;


    &:hover {
      border: 1px solid var(--theme-primary-yellow) !important;
    }

    img {
      max-width: 30px;
    }
  }

  &__wrapperItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    padding: 16px;
    border-radius: 20px;
    background:  #2D3543;
    box-shadow: 0px 4px 8px 0px rgba(255, 255, 255, 0.06) inset, 0px 4px 6px 0px rgba(21, 25, 30, 0.30);
    margin-bottom: 8px;
    transition: .3s all ease;
    -webkit-transition: .3s all ease;

  }

  &__item {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fff;
    padding: 0 18px;
    width: fit-content;
    gap: 8px;
    height: 100%;

    &.is_samsung_browser {
      background: #fff !important;
    }

    &__title {
      white-space: nowrap;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      transition: .3s all ease;
      -webkit-transition: .3s all ease;
      cursor: pointer;
    }

    &.current, &:hover {
      color: $primary-color;
    }
  }
}

.sliderButtonContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

}

@media (max-width: 1920px) {
  .slider {
    //@include sliderPaddingLeft;
  }
}

@media (max-width: 1750px) {
  .slider__alignLeft .swiper > div {
    justify-content: flex-start;
  }
}



@media (max-width: 1050px) {
  .slider {
    &__buttons_containert {
      button {
        position: absolute;
        top: 99px;

        &.left {
          left: 0;
          transform:translateY(-50%) translateX(-50%) scale(0.8);

        }

        &.right {
          right: 0;
          transform: translateY(-50%) translateX(75%) scale(0.8);
        }
      }
    }
  }
}

@media (max-width: 1000px){

  .sliderButtonContainer:first-child {
      margin-left: 12px;
  }

  .slider {
    padding-left: 0!important;
    margin-right: 0;

    &__innerRelative {
      //margin-top: 40px;
    }

    &__buttons_containert {
      display: none;
    }

    &__title {
      margin-top: 0;
    }
  }

  .slider__item__title {
    width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

@media (max-width: 757px) {
  .slider__sliderRelative {

    &.noPadding {
      margin-bottom: 0;
    }
  }

  .slider {
    &__swiper_item {
      height: 40px;

      .icon_div {
        height: 20px;
      }
    }
  }
}

@media (max-width: 400px){
  .swiper {
    //padding: 16px 0 16px 16px;
    margin-top: 0;
  }

  .slider {
    //margin-top: 16px;

    //&__innerRelative {
    //  margin-top: 30px!important;
    //}

    &.noMargin {
      margin-top: 0 !important;
    }

    &.noPadding {
      .sliderButtonContainer:first-child {
        margin-left: 0;
      }
    }
  }

  .sliderButtonContainer:first-child {

    &.noPaddingSlider {
      padding-left: 0;
    }
  }

  .sliderButtonContainer {
    &.firstItem {
      margin-left: 0;
    }
  }
}
