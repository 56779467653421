@import 'variables';
@import "mixins";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //align-items: flex-start;
  //-webkit-align-items: flex-start;
  //border: 1px solid #35303e;
  min-height: 56px;
  padding: 0 16px;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  cursor: pointer;
  user-select: none;
  position: relative;
  width: 100%;
  margin-right: 10px;
  border-radius: 30px;
  //border: 1px solid  $spins_border_gray_color;
  //background: var(--Site-BG, #15191E);
  //box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.15) inset, 0px -2px 1px 0px rgba(255, 255, 255, 0.10) inset;
  align-items: center;
  -webkit-align-items: center;
  resize: none;

  input {
    max-width: 100%;
  }

  &.noShadow {
    box-shadow: unset !important;
  }

  &:last-child {
    margin-right: 0;
    //padding: 8px 20px 8px;
  }

  &.hasIcon {
    padding-left: 8px;
  }

  &__inner {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    width: 100%;
    justify-content: flex-start;
    text-align: left;
    overflow: hidden;

    &__icon {
      margin-right: 8px;
    }
  }

  &__labelWrapper {
    display: flex;
    align-items: flex-end;
    -webkit-align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    //position: relative;
  }

  &__selected {
    text-transform: capitalize;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    margin-right: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__icon {
    margin-right: 12px;
    color: $primary-color;
  }

  &__label {
    text-transform: uppercase;
    font-size: 12px;
    //margin: 7px 0 0px 0;
    //line-height: 1.6;
    align-self: flex-start;
    color: $spins_text_gray_color;
    font-weight: 400;
    letter-spacing: 0;

    //margin-right: 8px;
    //color: $primary-color;
    //text-transform: uppercase;
  }

  &__caret {
    position: absolute;
    top: 50%;
    right: 12px;
    width: 35px;
    height: 35px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    //color: #84789C;
    transform: translateY(-50%);

    & svg {
      width: 14px;
      height: 12px;

    }

    //opacity: .5;
    //transform: rotate(0);
    //-webkit-transform: rotate(0);
    //transition: all .3s ease;
    //-webkit-transition: all .3s ease;
    //position: absolute;
    //right: 0;
    //top: -8px;

  }

  &__options {
    position: fixed;
    z-index: 1100;
    min-width: 250px;
    //width: 101%;
    //right: -1px;
    //top: -1px;
    background-color: $spins_gray_bg1;
    border-radius: 24px 8px 8px 24px;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
    padding: 8px;
    padding-left: 0;
    //transform: translateY(10px);
    //-webkit-transform: translateY(10px);
    //opacity: 0;
    //pointer-events: none;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    max-height: 300px;
    overflow: auto;

    &__item {
      cursor: pointer;
      height: 100%;
      //color: grey;
      //transition: all .3s;
      overflow: hidden;
      font-size: 1em;
      font-family: inherit;
      line-height: 1.5;
      text-overflow: ellipsis;
      box-sizing: content-box;
      padding: 12px 16px;
      display: flex;
      align-items: center;

      &__icon {
        margin-right: 8px;
      }

      &.selected, &:hover {
        border-left: 2px solid $spins-yellow-color;
        background: rgba(0,0,0,.12);
        color: #d2cddb;
      }

      &.selected {
        color: $spins-yellow-color;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.active {
    .root__caret {
      opacity: 1;
      transform: translateY(-50%) rotate(180deg);
      -webkit-transform: translateY(-50%) rotate(180deg);
    }

    .root__options {
      opacity: 1;
      transform: translateY(0);
      -webkit-transform: translateY(0);
      pointer-events: initial;
      z-index: 99999999;
    }
  }

  &:hover, &.active {
    //border-color: $spins_border_gray_color_hover;
    border-color: red;
  }
}
.closeModal {
  display: none;
}
@media (max-width: 767px)  {
  .root {
    //margin-bottom: 10px;
    min-height: 50px;

  }
}