.root {
  position: fixed;
  z-index: 199;
  bottom: 8px;
  left: 0;
  right: 0;
  display: none;
  align-items: center;
  -webkit-align-items: center;
  //background-color: #1E1E1E;
  height: 56px;
  justify-content: center;
  //padding: 0 21px;

  &.isSport {
    bottom: 54px;
  }

  .guestButtons {
    display:flex;
    align-items: center;
    gap: 8px;

    button {
      min-height: 40px;

    }
  }
  @keyframes spinsStyle {
    0% {
      -webkit-box-shadow: inset 0px 0px 10px 1px rgba(251,185,14,0.4);
      box-shadow: inset 0px 0px 10px 1px rgba(251,185,14,0.4);
    }
    50% {
      -webkit-box-shadow: inset 0px 0px 10px 1px rgba(251,185,14,0);
      box-shadow: inset 0px 0px 10px 1px rgba(251,185,14,0)
    }

    100% {
      -webkit-box-shadow: inset 0px 0px 10px 1px rgba(251,185,14,0.4);
      box-shadow: inset 0px 0px 10px 1px rgba(251,185,14,0.4);
    }
  }

  .blink {
    animation-name: spinsStyle;
    animation-iteration-count: infinite;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    color: var(--theme-primary-yellow);

    svg {
      transform: translateX(-1px);
    }
  }
}

@media (max-width: 1000px) {
  .root {
    display: flex;
  }
}