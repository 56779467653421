@import "variables";

.vector {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 27px;
  right: -5px;
  width: 16px;
  height: 16px;
  //background: #2D3543;
  border-radius: 50%;
  border: 1px solid transparent;
  transition: all .1s ease;
  -webkit-transition: all .1s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg{
    //transform: rotate(180deg);

    width: 12px;
    height: 6px;
    //margin-bottom: 11px;
    //margin-left: 1px;
  }
}


.wrapper {
  position: relative;
  transition: all .1s ease;
  -webkit-transition: all .1s ease;

  .not_verified_chevrone {
    box-shadow: 0px 4px 8px 0px rgba(255, 255, 255, 0.10) inset, 0px 4px 6px 0px rgba(224, 34, 34, 0.20);
  }

  .auth {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    //background: purple;
    //padding: 8px 10px;
    padding: 0;
    //border-radius: 50px;
    position: relative;
    cursor: pointer;
    color: white;
    //border: none;
    border: 2px solid transparent;
    transition: all .1s ease;
    -webkit-transition: all .1s ease;
    border-radius: 40px;

    img {
      width: 100%;
      height: 100%;

    }

    &.not_verified {
      border: 2px solid $spins-danger2 !important;
      box-shadow: 0px 4px 8px 0px rgba(255, 255, 255, 0.10) inset, 0px 4px 6px 0px rgba(224, 34, 34, 0.20);
    }


    &__name {
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      font-weight: 500;
      background: transparent;
      border: none;
      color: white;

    }
  }

  &.active {
    .dropDown {
      display: block;
    }

    .vector {
      position: absolute;
      top: 27px;
      right: -5px;
      width: 16px;
      height: 16px;
      //background: #2D3543;
      border-radius: 50%;
      transform: rotate(180deg);
      transition: all .1s ease;
      -webkit-transition: all .1s ease;

      & svg{
        width: 12px;
        height: 6px;
        //margin-bottom: 11px;
        //margin-left: 1px;
      }
    }

    .auth {
      border: 2px solid #FBB90E;
      transition: all .1s ease;
      -webkit-transition: all .1s ease;
    }
  }
}

.dropDown {
  border-radius: 20px;
  //background: #2D3543;
  //box-shadow: 0px 4px 8px 0px rgba(255, 255, 255, 0.06) inset, 0px 4px 6px 0px rgba(21, 25, 30, 0.30);
  top: 70px;
  right: -15px;
  padding: 15px;
  width: 240px;
  position: absolute;
  display: none;
  cursor: pointer;
  z-index: 500;

  &__name {
    color: #EEB665;
    padding: 10px 0 5px 8px;
    font-size: 14px;
    margin-bottom: 5px;
  }

  &__links {
    list-style: none;
    padding: 0 0 0 0;
    font-size: 15px;
    font-weight: 500;
    font-family: Poppins, "sans-serif";

    & li {
      display: flex;
      align-items: center;
      justify-content: space-around;
      //padding: 9px 8px;
      border-radius: 8px;
      //border: 1px solid #2D3543;
      //background: #191D26;
      margin-bottom: 4px;
      padding: 0 5px;

      & a {
        padding: 9px 8px;
        display: inline-block;
        width: 100%;
        color: white;
        text-decoration: none;
      }


      &:hover {
        background: #0E0C10;


        path {
          fill: white;
        }
      }
    }
  }
  &__logOut {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    color: white;
    padding: 0;
  }
  .userLogOut {
    //color: red;
    padding-left: 8px;
    font-size: 15px;
  }

  &:after {
    content: "";
    position: absolute;
    top: -22px;
    right: 13px;
    height: 0;
    border-left: 28px solid transparent;
    border-right: 22px solid transparent;
    border-bottom: 26px solid transparent;
    z-index: 1;
  }
  &:before {
    content: "";
    position: absolute;
    top: -14px;
    right: 25px;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 18px solid transparent;
  }
}























