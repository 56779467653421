@import "variables";

.header {
  padding-top: 10px;
  display: flex;
  gap: 20px;
  margin-top: 20px;

  .switch {
    margin-bottom: 0;
  }
}

.promotions_block {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  gap: 30px 20px;
}

@media (max-width: 1199px) {
  .header {
    .switch {
      margin-top: 0px;
    }
  }
}

@media (max-width: 795px){
.promotions_block {
  gap: 20px;
}
}

@media (max-width: 767px) {
  .header {
    margin: 20px 0;

    .switch {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 575px) {
  .header {
    flex-direction: column;
    align-items: start;
  }

  .promotions_block {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .header {
    .switch {
      width: 100%;
      margin-top: 0;
    }
  }
}

