@import 'variables';
@import "mixins";


.modal {
  width: fit-content !important;

  .modalContent {
    width: 417px;

    &__item {
      min-width: 80px;
      max-width: 80px;
      //border: 1px solid #fff;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 6px;
      //height: 200px;

       &:hover {
         color: $primary-color;
       }
    }

    &__buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__smName {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      margin-top: 5px;
    }

    &__smImage {
      //width: 100%;
      //width: 100px;
      //height: 100px;
      //border: 1px solid #000;
    }

    &__shareSection {
      width: 100%;
      padding: 8px 8px 8px 16px;
      //border: 1px solid $spins_gray_bg2;
      margin-top: 30px;
      //background-color: $spins_gray_bg3;
      border-radius: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 44px;

      .link {
        max-width: 220px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 171.429% */
        letter-spacing: 0.1px;
        text-overflow: ellipsis;
        //border: 1px solid #fff;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}

@media(max-width: 575px) {
  .modal {
    .modalContent {
      width: 330px;

      &__item {
        min-width: 70px;
        max-width: 70px;

        &:hover {
          color: $primary-color;
        }
      }

      &__smName {
        font-size: 12px;
      }

      &__smImage {
        //width: 100%;
        width: 70px;
        height: 70px;
        //border: 1px solid #fff;
      }
    }
  }
}

@media(max-width: 440px) {
  .modal {
    .modalContent {
      width: 280px;

      &__item {
        min-width: 60px;
        max-width: 60px;

        &:hover {
          color: $primary-color;
        }
      }

      &__smName {
        font-size: 11px;
      }

      &__smImage {
        //width: 100%;
        width: 60px;
        height: 60px;
        //border: 1px solid #fff;
      }

      &__shareSection {
        gap: 14px;

        .link {
          width: 50%;
        }

        .button {
          font-size: 12px !important;
        }
      }
    }
  }
}

@media(max-width: 440px) {
  .modal {
    .modalContent {
      width: 230px;
      gap: 10px;

      &__item {
        min-width: 50px;
        max-width: 45px;

        &:hover {
          color: $primary-color;
        }
      }

      &__smName {
        font-size: 9px;
      }

      &__smImage {
        //width: 100%;
        width: 45px;
        height: 45px;
        //border: 1px solid #fff;
      }

      &__shareSection {
        gap: 8px;

        .link {
          width: 50%;
        }

        .button {
          font-size: 10px !important;
          padding: 10px 16px !important;
          min-height: unset;
        }
      }
    }
  }
}