@import "mixins";
@import "variables";


.realityCheck {

  .reality_check_control {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    padding: 0 3px 20px 3px;

    button {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__table {
    width: 100%;
  }

  &__table_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 46px;
    border-bottom: 1px solid $spins_btn_bgColor_primary;
  }
}

.returnToLimits {

  h2 {
    font-size: 28px;
  }

  p {
    margin-top: 12px;
    font-size: 16px;
  }

  color: #fff;
  .limitsBtnWrapper {
    margin-top: 20px;

    & > button {
      margin: 8px;
      width: 230px;
    }
  }
}

.upload_document {
  max-width: 700px;
  width: 700px;

  &__info_text {
    text-align: start;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    color: $spins-dark_text;
  }

  &__content {
    form {
      display: flex;
      flex-direction: column;

      .upload_rules {
        display: flex;
        flex-direction: column;
        text-align: start;
        gap: 20px;

        span {
          font-size: 14px;
          font-weight: 600;
        }

        ul {
          //list-style: none;
          padding-left: 18px;
          font-size: 14px;
          margin: 0;
          transform: none;

          li {
            margin-bottom: 2px;
          }
        }
      }
    }
  }

  &__upload_input {
    border: 1px solid #fff;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.1;
    max-width: 1px;
    max-height: 1px;
  }

  &__input_container {
    position: relative;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-decoration-line: underline;
    margin-left: 7px;
    cursor: pointer;
    transform: translateY(2px);

  }

  &__upload_file {
    border-radius: 16px;
    height: 395px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin: 30px 0;
    position: relative;
    overflow: hidden;

    &.img_container {
      img {
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        width: auto;
      }

      button {
        position: absolute;
        bottom: 0;
        border: none;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.70) 100%);
        width: 100%;
        text-transform: uppercase;
        color: $spins_gray_bg2;
        padding: 16px 16px 8px 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 7px;
        z-index: 1;

        svg {
          transform: translateY(-2px);

        }
      }

      .upload_document__drag_overlay {
        //background: rgba(14, 12, 16, 0.27);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color: #fff;
      }
    }
  }

  &__drag_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $spins_gray_bg3;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: $spins_text_gray_color;
  }
}

.forgot_password {

  &__info {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: $spins_text_gray_color;
    text-align: start;
  }
}

.verification_modal {
  overflow: visible;
  padding: 0 !important;

  &__content {
    max-width: 608px;
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  &__info_text {
    text-align: start;
    font-size: 13px;
    @include contentModalWidth;
  }

  &__swith_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include contentModalWidth;
    overflow: visible;
    height: 40px;
    border-radius: 100px;

    button {
      width: calc(50% + 4px);
      height: calc(100% + 2px);
      border-radius: 100px;
      //background: none;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;

      svg {
        margin-right: 4px;
      }

      &.left {
        transform: translateX(-1px) translateY(-0.1px);
      }

      &.right {
        transform: translateX(1px) translateY(-0.1px);
      }
    }
  }

  &__form_block {
    @include contentModalWidth;
    margin-bottom: 24px;
  }
}

.withdraw_modal {
  padding: 0 !important;

  &__form {
    padding: 0 24px;
  }

  &__check_boxes {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;

    .box {
      margin: 0 !important;

      .checkmark_label {
        display: flex;
        position: relative;
        cursor: pointer;
        font-size: 22px;
        width: 100%;
        gap: 8px;
        align-items: center;
        padding: 0 0 0 34px;

        input, .checkmark {
          position: absolute;
          top: 3px;
          left: 0;
          width: 100%;
          height: 100%;
        }

        input {
          width: 0;
          height: 0;
          cursor: pointer;
          opacity: 0;
        }

        .checkmark {
          height: 24px;
          width: 24px;
          border-radius: 50px;
          background-color: transparent;
          border: 2px solid $spins-dark_text;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          color: #000;

          svg {
            opacity: 0;
          }
        }

        input[type="radio"]:checked ~ .checkmark {
          background: $spins_yellow_1;
          border: 2px solid #000;
          svg {
            opacity: 1;
          }
        }
      }
    }
  }
}

.reset_pwd_modal {
  form {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    gap: 16px;
    padding-bottom: 24px;

    p {
      margin: 0;
      text-align: start;
    }

    button {
      width: fit-content;
      margin: 0 auto;
    }

    .errortext {
      font-size: 10px;
      color: $error;
      margin-top: 6px;
    }

    .pwd_rules {
      span {
        font-size: 12px;
      }
      
      @media (max-width: 400px) {
        span {
          font-size: 10px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .upload_document {
    max-width: 400px;
    width: 400px;

    &__upload_file {
      height: 250px;
    }

    &__content {
      form {
        .upload_rules {
          gap: 10px;

          span {
            font-size: 13px;
          }

          ul {
            padding-left: 12px;
            font-size: 12px;
            font-weight: 500;

            li {
              margin-bottom: 2px;
            }
          }
        }
      }
    }
  }

  .returnToLimits {
    max-width: 500px;
  }


}

@media (max-width: 676px) {
  .verification_modal {
    &__content {
      max-width: 500px;

    }
  }
}

@media (max-width: 575px) {
  .realityCheck {
    .reality-check-control {
      flex-direction: column;
      -webkit-flex-direction: column;

      button:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  .verification_modal {
    &__content {
      max-width: 500px;

    }

    &__swith_container {
      height: auto;
      padding: 4px;
      border-radius: 16px;

      button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px;
        min-height: 54px;
        border-radius: 12px;

        svg {
          margin-right: 4px;
        }

        &.left {
          transform: translateX(-1px) translateY(-0.1px);
        }

        &.right {
          transform: translateX(1px) translateY(-0.1px);
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .realityCheck {
    .reality_check_control {
      button {
        //font-size: 12px!important;
      }
    }
  }

  .upload_document {
    max-width: 340px !important;
    width: 340px !important;

    &__upload_file {
      height: 200px;
    }

    &__info_container {
      flex-direction: column;

    }

    &__drag_doc_text {
      font-size: 10px;
    }
  }
}

@media (max-width: 482px) {
  .returnToLimits {

  }
}

@media (max-width: 430px) {
  .verification_modal {
    &__swith_container {
      button {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 400px) {
  .realityCheck {
    .reality_check_control {
      button {
        font-size: 11px!important;
      }
    }
  }
}

@media (max-width: 380px) {
  .verification_modal {
    &__swith_container {
      button {
        padding-right: 20px;
        padding-left: 20px;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 370px) {
  .realityCheck {
    .reality_check_control {
      button {
        font-size: 9px!important;
      }
    }
  }
}

