@import "variables";
@import "mixins";

.page {
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 56px;
  padding-bottom: 80px;
  height: 100%;
  overflow: hidden;

  &__image {
    width: 1027px;
    height: 387px;
  }

  h1 {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
  }

  button {
    width: 64px;
    min-height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    transition: 0.3s all;
    -webkit-transition: 0.3s all;

    svg {
      transform: translateY(2px);
    }
  }
}

@media (max-width: 1300px) {
  .container {
    &__image {
      width: 90%;
      height: auto;
    }
  }
}


@media (max-width: 850px) {
  .container {
    gap: 20px;
    justify-content: start;

    &__image {
      width: 1200px;
    }
  }
}


@media (max-width: 490px) {
  .container {
    &__image {
      width: 1000px;
      height: auto;
    }

    h1 {
      font-size: 28px;
      font-weight: 700;
      text-align: center;
      max-width: 95%;
    }
  }
}