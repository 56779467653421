@import "variables";
@import "mixins";


.main {

  .content {
    .body {
      background: linear-gradient(180deg, rgba(122, 61, 231, 0.20) 0%, rgba(202, 50, 225, 0.20) 100%), rgba(87, 101, 129, 0.3);
    }
  }

  &.regalJackpot {
    .content {
      .body {
        background: linear-gradient(180deg, rgba(122, 61, 231, 0.20) 0%, rgba(202, 50, 225, 0.20) 100%), rgba(87, 101, 129, 0.3);
      }
    }
  }


  &.playJackpot {
    .content {
      .body {
        background: linear-gradient(180deg, rgba(138, 83, 238, 0.20) 0%, rgba(13, 108, 128, 0.20) 100%), rgba(87, 101, 129, 0.3);
      }
    }
  }

  &.clover {
    .content {
      .body {
        background: linear-gradient(180deg, rgba(39, 211, 149, 0.20) 0%, rgba(21, 152, 120, 0.20) 100%), rgba(87, 101, 129, 0.3);
      }
    }
  }

  &.hotLuck {
    .content {
      .body {
        background: linear-gradient(180deg, rgba(4, 83, 201, 0.20) 0%, rgba(40, 210, 233, 0.20) 39.5%, rgba(83, 8, 128, 0.20) 100%), rgba(87, 101, 129, 0.3);
      }
    }
  }

  &.bellLink {
    .content {
      .body {
        background: linear-gradient(180deg, rgba(90, 29, 221, 0.20) 0%, rgba(207, 84, 237, 0.20) 39.5%, rgba(18, 11, 46, 0.20) 100%), rgba(87, 101, 129, 0.3);
      }
    }
  }

  &.highCash {
    .content {
      .body {
        background: linear-gradient(180deg, rgba(99, 12, 168, 0.20) 0%, rgba(223, 152, 180, 0.20) 39.5%, rgba(84, 11, 115, 0.20) 100%), rgba(87, 101, 129, 0.3);        }
    }
  }

  &.jackpotCards {
    .content {
      .body {
        background: linear-gradient(180deg, rgba(251, 253, 159, 0.20) 0%, rgba(207, 57, 57, 0.20) 39.5%, rgba(199, 23, 23, 0.20) 100%), rgba(87, 101, 129, 0.3);
      }
    }
  }

  &.egypt {
    .content {
      .body {
        background: linear-gradient(180deg, rgba(221, 179, 29, 0.20) 0%, rgba(237, 167, 84, 0.20) 39.5%, rgba(220, 92, 19, 0.20) 100%), rgba(87, 101, 129, 0.3);
      }
    }
  }
}


.main {
  position: relative;
  margin-bottom: 16px;
  padding: 0 50px;
  border-bottom: 1px solid var(--theme-var-bg-main);

  @media (max-width: 600px) {
    .inner {
      max-height: 370px;
    }
  }

  .content {
    position: relative;
    max-width: 100vw;
    margin: 0 auto;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;


    .header {
       height: 120px;
       display: flex;
       align-items: center;
       max-width: 100%;
      position: relative;
      z-index: 1;

       .games {
         font-size: 24px;
         font-style: normal;
         font-weight: 700;
         line-height: normal;
       }

       .end, .games {
         flex: 1;
       }

       .divider {
         display: none;
         width: 2px;
         background: $dark_text3;
         height: 24px;
         border-radius: 10px;
       }

       .end {
         display: flex;
         justify-content: end;
         transform: translateX(-2px);
       }

       .img_container {
         width: 312px;
         max-width: 312px;
         height: 120px;
         display: flex;
         justify-content: center;

         img {
           width: 312px;
           max-width: 312px;
           //max-width: calc(100vw - 40px);
           object-fit: contain;
         }

       }

       @media (max-width: 1400px) {
         flex-wrap: wrap;
         justify-content: center;
         height: fit-content;
         align-items: center;
         gap: 16px;

         .img_container {
           order: -1;
           flex: 1;
           min-width: 100%;
           height: fit-content;

           img {
             width: 200px;
             max-width: 200px;
           }
         }

         .divider {
           display: block;
         }

         .end, .games {
           flex: unset;
           font-size: 16px;

           img {
             height: 40px;
             width: auto;
           }
         }
       }
     }

    .body {
      max-width: 100%;
      border-radius: 24px;
      padding: 100px 0 32px 0;
      position: relative;
      margin-top: -50px;

      .bretton {
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
      }

      .inner {

        .slider > div {
          justify-content: center;

          @media (max-width: 1400px) {
            justify-content: unset;
          }
        }

        .slider {
          max-width: 100%;

          .slide {
            width: fit-content;

            .jp_level {
              width: 292px;
              height: 340px;
              position: relative;

              .image {
                position:absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;


                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                  object-position: center center;
                }

              }

              .jv_info {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 10px;
                position: relative;

                .top {
                  position: relative;
                  min-height: 115px;

                  strong {
                    position: absolute;
                    top: 70%;
                    left: 50%;
                    transform: translate(-55%, -50%);
                    font-size: 24px;
                    text-transform: capitalize;
                    color: #fff;
                  }
                }

                .center_container {
                  width: 100%;
                }

                .center {
                  padding: 10px;
                  border-radius: 40px;
                  backdrop-filter: blur(15px);
                  min-height: 72px;
                  display: flex;
                  align-items: center;
                  gap: 8px;

                  .val {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;

                  }

                  .valueContainer {
                    //font-weight: 700 !important;
                    font-family: Roboto !important;
                  }

                  .ron {
                    font-family: Roboto;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 20px;

                  }

                  .badge {
                    @include jpBadge;
                    min-height: 50px !important;

                  }
                }

                .numbers {
                  display: flex;
                  flex-direction: column;
                  gap: 16px;
                  width: 100%;
                  padding: 0 36px;
                  margin-top: 24px;

                  .nm {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 8px;

                    span {
                      font-size: 12px;
                      font-style: normal;
                      //font-weight: 600;
                      line-height: 14px;
                      color: #fff;
                      font-weight: 400;
                    }

                    strong {
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 120%;
                      color: var(--theme-primary-yellow);
                    }

                    &.latest_date {
                      strong {
                        color: #fff;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 16px;
                      }
                    }
                  }

                }


              }

              .bottom {
                width: calc(100% - 56px);
                position: absolute;
                bottom: 0;
                left: 50%;
                height: 200px;
                transform: translateX(-50%);
                display: flex;
                align-items: end;
                overflow: hidden;
                border-radius: 16px;
                pointer-events: none;
                //border: 1px solid #F00;

                &:after {
                  content: "";
                  border-radius: 16px;
                  width: 100%;
                  -webkit-box-shadow: 0px -4px 21px 15px rgba(0,0,0,0.3);
                  box-shadow: 0px -4px 21px 15px rgba(0,0,0,0.3);
                  height: 130px;
                }
              }
            }

            @media (max-width: 1400px) {
              &:first-child {
                margin-left: 24px;
              }

              &:last-child {
                margin-right: 24px;
              }

              .jp_level {
                .jv_info {
                  .center {
                    min-height: 72px;

                    .badge {
                      @include jpBadge;
                      min-height: 24px !important;
                    }
                  }

                  .numbers {
                    margin-top: 20px;
                  }
                }
              }
            }

            @media (max-width: 600px) {
              .jp_level {
                width: 148px;
                height: 190px;

                .jv_info {
                  padding-top: 6px;

                  .top {
                    min-height: unset;
                    max-height: 58px;

                    strong {
                      font-size: 12px;
                    }

                    .leveling {
                      transform: scale(0.48);
                      transform-origin: top center;
                    }
                  }

                  .center {
                    min-height: 32px;
                    padding: 0 4px;
                    gap: 4px;
                    justify-content: end;
                    //margin: 0 -100px;
                    margin-left: -5px;
                    margin-right: -5px;

                    .valueContainer {
                      font-size: 11px !important;
                      max-height: 11px !important;
                      //transform: scale(0.6);
                      //font-weight: 700 !important;
                      font-family: Roboto !important;
                    }

                    .ron {
                      font-size: 11px;
                      font-style: normal;
                      font-weight: 800;
                    }

                    .badge {
                      font-size: 9px;
                      padding: 5px;
                      line-height: 10px;
                    }
                  }

                  .numbers {
                    margin-top: 16px;
                    padding: 0 16px;
                    gap: 8px;

                    .nm {
                      gap: 2px;

                      span {
                        font-size: 10px;
                      }

                      strong {
                        font-size: 12px;
                      }

                      &.latest_date {
                        strong {
                          font-size: 12px;
                        }
                      }
                    }
                  }
                }

                .bottom {
                  width: calc(100% - 16px);

                  &:after {
                    height: 87px;
                  }
                }
              }
            }
          }
        }

      }

    }
  }

  @media (max-width: 1400px) {
    padding: 0;

    .content {
      .body {
        border-radius: 0;

        .bretton {
          width: 320px;
          top: -24px;
        }
      }
    }
  }

  @media (max-width: 600px) {

    .content {

      .header {
        gap: 8px;

        .games {
          font-size: 16px;
        }
      }

      .body {
        padding: 72px 0 16px 0;

        .bretton {
          width: 280px;
          top: -26px;
        }
      }
    }
  }
}