@import 'variables';

.input__field {
  padding:24px 20px 12px 20px;
  width: 360px;
  height: 56px;
  //background: #161827;
  //background: black;
  //border: 1px solid transparent;
  border-radius: 30px;
  position: relative;


  &_block {
    text-align: start;
    position: relative;
    margin-bottom: 24px;

    &.no_shadow {
      input {
        box-shadow: unset !important;
        -webkit-box-shadow: unset !important;
      }
    }

    &.no_margin  {
      margin-bottom: 0;
    }

    & input {
      width: 100%;
    }

    input {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: textfield;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      outline: none;
      margin: 0 !important;
      border-radius: 30px;


      &:-webkit-autofill {
        outline: none !important;
        border: 1px solid  #2D3543!important;


        &:not(:focus) {
          border-radius: 30px;
          border: 1px solid  #2D3543;
          background: #15191E;
          box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.15) inset, 0px -2px 6px 0px rgba(255, 255, 255, 0.06) inset;
        }
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
        -webkit-appearance: none;
      }


      &:focus {

        &:placeholder-shown + .label {
          color: $spins-primary-color;
        }

        //&.contactForm-btnClose {
        //  display: none;
        //}

        &:not(:placeholder-shown) + .label {
          color: $spins-primary-color;
        }
      }

      &:focus ~ .contactForm__btnClose {
        opacity: 1;
      }

      &:placeholder-shown + .label {
        transform: translateY(20px);
        text-transform: capitalize;

      }

      &:placeholder-shown + .label ~ .contactForm-btnClose {
        display: none;
      }

      &::-webkit-input-placeholder {
        opacity: 0;
      }
      &::-moz-placeholder {
        opacity: 0;
      }
      &:-ms-input-placeholder {
        opacity: 0;
      }
      &:-moz-placeholder {
        opacity: 0;
      }

      &:focus::-webkit-input-placeholder  {
        //opacity: 1;
      }

      &:not(:placeholder-shown) + .label,
      &:focus + .label {
        transform: translateY(8px) scale(1);
        cursor: pointer;
        //color: #EEB665;


      }
    }
  }

  &::placeholder {
    font-weight: 400;
    font-size: 12px;
    line-height: 8px;
    color: #84789C;
    opacity: 1;

  }

  &_passwordBtn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 18px;
    background: transparent;
    border: none;
    cursor: pointer;
    //color: #84789C;

    &.icon__color {
      color: #fff;
    }
  }
}

.label {
  position: absolute;
  left: 20px;
  text-transform: capitalize;
  pointer-events: none;
  transition: all .2s ease;
  -webkit-transition: all .2s ease;

  color: $spins-dark_text;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
}

.contactForm__btnClose {
  background: transparent;
  border: none;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #84789C;
  opacity: 0;
  & svg {
    width: 12px;
    height: 12px;
  }

}







































//@import 'variables';
//
//.input__field {
//  background: green;
//}
//
//.main {
//  position: relative;
//  height: fit-content;
//  background-image: linear-gradient( 180deg ,#262d39, #424955);
//  padding: 1px;
//  border-radius: 100px;
//
//  //border: 1px solid #fff;
//
//  &.focused {
//    background: #EEB665;
//  }
//
//  &__input {
//    padding:22px 20px 12px 20px;
//    width: 100%;
//    height: 56px;
//    background: $spins_gray_bg2;
//    box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.15) inset, 0px -2px 1px 0px rgba(255, 255, 255, 0.10) inset;
//    border: none;
//    border-radius: 30px;
//    color: #fff;
//    appearance: none;
//    -webkit-appearance: none;
//    -moz-appearance: textfield;
//    font-weight: 500;
//    font-size: 14px;
//    line-height: 24px;
//    letter-spacing: 0.1px;
//    outline: none;
//    margin: 0 !important;
//    padding-top: 30px;
//
//    &.input_search {
//      width: 178px;
//      height: 40px;
//      padding: 8px 75px 8px 15px;
//      -webkit-transition: 0.3s all;
//      transition: 0.3s all;
//
//      &:focus {
//        width: 351px;
//        -webkit-transition: 0.3s all;
//        transition: 0.3s all;
//      }
//    }
//
//    &:-webkit-autofill {
//      box-shadow: 0 0 0 1000px #1b1b1b inset !important;
//      -webkit-box-shadow: 0 0 0 1000px #1b1b1b inset !important;
//      -webkit-text-fill-color: white !important;
//      outline: none !important;
//
//      &:not(:focus) {
//        border-color: #1F1B24 !important;
//      }
//    }
//
//    &::-webkit-outer-spin-button,
//    &::-webkit-inner-spin-button {
//      appearance: none;
//      -webkit-appearance: none;
//    }
//    //&.contactForm-btnClose {
//    //  display: none;
//    //}
//
//    &:focus {
//
//      &:placeholder-shown + .label {
//        color: #EEB665;
//      }
//
//      &:not(:placeholder-shown) + .label {
//        color: #EEB665;
//      }
//    }
//
//    &:focus ~ .contactForm__btnClose {
//      opacity: 1;
//    }
//
//    &:placeholder-shown + .label {
//      text-transform: uppercase;
//    }
//
//    &:placeholder-shown + .label ~ .contactForm-btnClose {
//      display: none;
//    }
//
//    &::-webkit-input-placeholder {
//      opacity: 0;
//    }
//    &::-moz-placeholder {
//      opacity: 0;
//    }
//    &:-ms-input-placeholder {
//      opacity: 0;
//    }
//    &:-moz-placeholder {
//      opacity: 0;
//    }
//
//    &:focus::-webkit-input-placeholder  {
//      //opacity: 1;
//    }
//
//    &:not(:placeholder-shown) + .label,
//    &:focus + .main {
//      padding: 0;
//    }
//
//    &:before {
//      content: '';
//      display: block;
//      position: absolute;
//      z-index: 0;
//      top: 1px;
//      left: 1px;
//      right: 1px;
//      bottom: 1px;
//      background: #ff0000;
//      border-radius: 20px;
//    }
//
//
//    &::placeholder {
//      font-size: 14px;
//      font-style: normal;
//      font-weight: 600;
//      line-height: 16px;
//      color: $spins-dark_text;
//      opacity: 1;
//    }
//  }
//
//  &__iconContainer {
//    position: absolute;
//    top: 0;
//    right: 20px;
//    display: flex;
//    flex-direction: row;
//    height: 100%;
//    justify-content: center;
//    align-items: center;
//    gap: 8px;
//    color: $spins-dark_text;
//
//    svg {
//      path {
//        color: $spins-dark_text;
//      }
//    }
//
//    .search {
//      svg {
//        height: 20px;
//        stroke: $spins-dark_text;
//      }
//    }
//  }
//
//  &__passwordBtn {
//    right: 18px;
//    background: transparent;
//    border: none;
//    cursor: pointer;
//    color: #84789C;
//
//    &.icon__color {
//      color: #fff;
//    }
//  }
//
//  &__btnClose {
//    background: transparent;
//    border: none;
//    color: #84789C;
//    opacity: 1;
//
//    & svg {
//      width: 12px;
//      height: 12px;
//    }
//  }
//}
//
//.label {
//  transform: translateY(8px);
//  color: $spins-dark_text;
//  position: absolute;
//  left: 20px;
//  font-size: 14px;
//  font-style: normal;
//  font-weight: 600;
//  line-height: 16px;
//  transition: all .2s ease;
//  -webkit-transition: all .2s ease;
//}